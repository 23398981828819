import React, { useEffect, useState, useCallback } from 'react';
import TopBar from '../../components/TopBar'
import { Container } from '@mui/system'
import TableRow from '@mui/material/TableRow'
import HomeHeader from '../../components/HomeHeader'
import { Grid, Typography, Box, Button } from '@mui/material'
import './style.scss'
import HomeTopBar from '../../components/HomeTopBar'
import HomeGolfHolidays from '../HomeGolfHolidays/'
import Testimonials from '../HomeTestimonials/'
import HomeBanner from '../Homebanner/'
import Footer from '../../components/Footer'
import BookingDetails from '../Booking/BookingDetails'
import Hoursupport from '../../assets/images/24hour-support.png'
import Quality from '../../assets/images/quality.png'
import Gps from '../../assets/images/gps.png'
import Excellent from '../../assets/images/excellent.png'
import safe_secure from '../../assets/images/safe_secure.png'
import best_quality from '../../assets/images/best_quality.png'
import car from '../../assets/images/car.png'
import rectangle1 from '../../assets/images/Rectangle 18.png'
import rectangle2 from '../../assets/images/Rectangle 19.png'
import rectangle3 from '../../assets/images/Rectangle 20.png'
import rectangle4 from '../../assets/images/Rectangle 21.png'
import rectangle5 from '../../assets/images/Rectangle 22.png'
import eventimg3 from '../../assets/images/Rectangle 40.png'
import eventimg2 from '../../assets/images/Rectangle 38.png'
import eventimg1 from '../../assets/images/Rectangle 31.png'
import dis1 from '../../assets/images/Rectangle 13112.png'
import dis2 from '../../assets/images/Rectangle 13113.png'
import dis3 from '../../assets/images/Rectangle 13114.png'
import home from '../../assets/images/home.png'
import Golfex from '../../assets/images/Golfex.png'

import _ from 'lodash';


import hotsecimg1 from '../../assets/images/gallery logo.png'
import holi1 from '../../assets/images/wh-1.png'
import holi2 from '../../assets/images/holi2.png'
import holi3 from '../../assets/images/holi3.png'
import holi4 from '../../assets/images/holi4.png'
import navlogo from '../../assets/images/GTLogo_New 2.png'
import whatsapp from '../../assets/images/_whatsapp_.png'
import blackvideo1 from '../../assets/images/-d98d-4e48-ae76-c5821603c9e3.mp4'
import homevideo from '../../assets/images/-ea2e-40e6-a004-f9350b2209dd.mp4'
import BookingForm from './BookingForm'
import { useSelector } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';



const Home = () => {
    //process.env.REACT_APP_GOOGLE_KEY


    const navigation = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const { user } = useSelector(state => state.auth);


    // useEffect(() => {
    //     if (user) {
    //         navigation('/dashboard')
    //     }
    // }, [user])

    // --------

    const [selectedButton, setSelectedButton] = useState('Golf Holidays');

    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };


    const SearchResult = () => {
        setAnchorElNav(null);
        navigation('/SearchResult')
    };


    // ----date
    const [startDate, setStartDate] = useState(new Date());


    // ------------


    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ]
      };
    

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    var eventsettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    
    var bgslider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    //   tab-------------------

    // const [value, setValue] = React.useState('1');

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    //   tab-------------------


    const handleScroll = useCallback(_.throttle(() => {
        const header = document.querySelector('.mainHeader');
        const toggleClass = 'is-sticky';
        const currentScroll = window.pageYOffset;
        if (header) {
            if (currentScroll > 150) {
                header.classList.add(toggleClass);
            } else {
                header.classList.remove(toggleClass);
            }
        }
    }, 100), []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);







    return (

        <div className='full-width-home'>
            {/* <div className='header-section'>
                <Container>
                    <div class="header-section-list">
                        <div class="logo">
                            <img src={navlogo} alt="logo" />
                        </div>
                        <div class="log-reg-section">
                            <div class="whatsapp-icon">
                                <img src={whatsapp} alt="icon" />
                            </div>
                            <p class="phone-icon"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_1029_11208)">
                                    <path d="M6.62 10.79C8.06 13.62 10.38 15.93 13.21 17.38L15.41 15.18C15.68 14.91 16.08 14.82 16.43 14.94C17.55 15.31 18.76 15.51 20 15.51C20.55 15.51 21 15.96 21 16.51V20C21 20.55 20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4 3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z" fill="#684B2A" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1029_11208">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg></p>
                            <ul class="header-currency">
                                <li>USD <span><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
                                    <path d="M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z" fill="#684B2A" />
                                </svg></span></li>
                            </ul>
                            <div class="log-reg-btn">
                                <button><a href="#">Login/Signup</a></button>
                            </div>
                        </div>
                    </div>
                </Container>
            </div> */}
            <div className="mainHeader">
                <div className="header-width-pad">
                    {/* <HomeHeader /> */}
                    <HomeTopBar activeMenu='1' />
                </div>
            </div>
            <div className="home-search">
            <video autoPlay="true" loop muted playsinline className="background-video">
                    <source src={homevideo} type='video/mp4' />
                </video>
                <div className="full-con-width">
                    <div className="home-content">
                        <h1 className="home-title">A Truly Premium Golf Experience</h1>
                        <p className="home-subtitle">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots
                            in a piece of classical Latin literature from 45 BC</p>
                            <BookingForm />
                        <div className="home-bottom-section">
                            <ul>
                                <li>Experiences</li>
                                <li>Get a Free Quote</li>
                                <li>Build Your Own Package !</li>
                            </ul>
                        </div>
                        <div className="home-socialicon-list">
                            <div className="soci-img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="23" viewBox="0 0 12 23" fill="none">
                                    <path d="M7.8961 22.343V12.2445H11.2998L11.8108 8.2909H7.8961V5.77495C7.8961 4.63174 8.21222 3.8566 9.8491 3.8566H11.9233V0.331675C11.5639 0.284041 10.3254 0.175781 8.88342 0.175781C5.87381 0.175781 3.81256 2.01186 3.81256 5.38522V8.29523H0.421875V12.2489H3.81256V22.343H7.8961Z" fill="white" />
                                </svg>
                            </div>
                            <div className="soci-img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="23" viewBox="0 0 18 23" fill="none">
                                    <path d="M8.13815 0.170036C4.53911 0.581736 0.952699 3.56519 0.804699 7.82695C0.711617 10.4291 1.43273 12.3812 3.84971 12.9293C4.8986 11.0336 3.51138 10.6154 3.29568 9.24391C2.40966 3.6237 9.62262 -0.209119 13.3972 3.71465C16.0088 6.43165 14.2896 14.7905 10.0773 13.9217C6.0426 13.0919 12.0523 6.43814 8.83176 5.13165C6.21385 4.06998 4.82247 8.37944 6.06372 10.5201C5.3363 14.2012 3.76937 17.67 4.40375 22.2871C6.46128 20.7574 7.15489 17.8282 7.72369 14.7732C8.75776 15.4166 9.30967 16.0861 10.6292 16.1902C15.4949 16.5758 18.2122 11.2134 17.5482 6.26699C16.9583 1.88168 12.6867 -0.349952 8.13815 0.170036Z" fill="white" />
                                </svg>
                            </div>
                            <div className="soci-img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                    <path d="M14.9361 0.634766H17.7504L11.6027 7.67906L18.8357 17.2663H13.173L8.73462 11.4529L3.66178 17.2663H0.844848L7.41987 9.72917L0.484375 0.636076H6.29127L10.2971 5.94879L14.9361 0.634766ZM13.9464 15.578H15.5063L5.43924 2.23527H3.76665L13.9464 15.578Z" fill="white" />
                                </svg>
                            </div>
                            <div className="soci-img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M21.1448 13.4785V21.5939H16.6487V14.0223C16.6487 12.1212 15.9995 10.8229 14.3716 10.8229C13.1293 10.8229 12.3912 11.697 12.0653 12.5433C11.947 12.8458 11.9164 13.2658 11.9164 13.69V21.5936H7.41994C7.41994 21.5936 7.48029 8.7698 7.41994 7.44236H11.9168V9.44773C11.9077 9.46352 11.895 9.47896 11.8869 9.49405H11.9168V9.44773C12.5143 8.48557 13.5799 7.11007 15.969 7.11007C18.9271 7.11007 21.1448 9.13262 21.1448 13.4785ZM2.71621 0.620972C1.17814 0.620972 0.171875 1.67752 0.171875 3.06566C0.171875 4.42432 1.14897 5.51139 2.65719 5.51139H2.68637C4.25461 5.51139 5.22969 4.42432 5.22969 3.06566C5.19985 1.67752 4.25461 0.620972 2.71621 0.620972ZM0.439117 21.5939H4.93395V7.44236H0.439117V21.5939Z" fill="#F1F2F2" />
                                </svg>
                            </div>
                            <div className="soci-img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="19" viewBox="0 0 27 19" fill="none">
                                    <path d="M26.2245 3.02618C25.9242 1.90008 25.0359 1.0117 23.9057 0.70725C21.8621 0.160889 13.6628 0.160889 13.6628 0.160889C13.6628 0.160889 5.46763 0.160889 3.41987 0.70725C2.29382 1.00754 1.40552 1.89593 1.10106 3.02618C0.554687 5.06987 0.554688 9.33654 0.554688 9.33654C0.554688 9.33654 0.554687 13.6033 1.10106 15.6469C1.40135 16.773 2.28965 17.6614 3.41987 17.9659C5.46763 18.5122 13.6628 18.5122 13.6628 18.5122C13.6628 18.5122 21.8621 18.5122 23.9057 17.9659C25.0318 17.6655 25.9201 16.7772 26.2245 15.6469C26.7709 13.6033 26.7709 9.33654 26.7709 9.33654C26.7709 9.33654 26.7709 5.06987 26.2245 3.02618Z" fill="white" />
                                    <path d="M11.0469 13.2701L17.8574 9.33706L11.0469 5.40405V13.2701Z" fill="#FF0000" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="home-search">
            <div className='bgbackhome'>
                    <Slider {...bgslider}>
                        <div className='bgslider-home'>
                            <img src={Golfex} alt="bg-image" className="background-image" />
                        </div>
                        <div className='bgslider-home'>
                            <img src={Golfex} alt="bg-image" className="background-image" />
                        </div>
                    </Slider>
                </div>
            <div className="full-con-width">
                <div className="home-content">
                    <h1 className="home-title">A Truly Premium Golf Experience</h1>
                    <p className="home-subtitle">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots
                        in a piece of classical Latin literature from 45 BC</p>
                    <BookingForm />
                    <div className="home-bottom-section">
                        <ul>
                            <li>Experiences</li>
                            <li>Get a Free Quote</li>
                            <li>Build Your Own Package !</li>
                        </ul>
                    </div>
                    <div className="home-socialicon-list">
                        <div className="soci-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="23" viewBox="0 0 12 23" fill="none">
                                <path d="M7.8961 22.343V12.2445H11.2998L11.8108 8.2909H7.8961V5.77495C7.8961 4.63174 8.21222 3.8566 9.8491 3.8566H11.9233V0.331675C11.5639 0.284041 10.3254 0.175781 8.88342 0.175781C5.87381 0.175781 3.81256 2.01186 3.81256 5.38522V8.29523H0.421875V12.2489H3.81256V22.343H7.8961Z" fill="white" />
                            </svg>
                        </div>
                        <div className="soci-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="23" viewBox="0 0 18 23" fill="none">
                                <path d="M8.13815 0.170036C4.53911 0.581736 0.952699 3.56519 0.804699 7.82695C0.711617 10.4291 1.43273 12.3812 3.84971 12.9293C4.8986 11.0336 3.51138 10.6154 3.29568 9.24391C2.40966 3.6237 9.62262 -0.209119 13.3972 3.71465C16.0088 6.43165 14.2896 14.7905 10.0773 13.9217C6.0426 13.0919 12.0523 6.43814 8.83176 5.13165C6.21385 4.06998 4.82247 8.37944 6.06372 10.5201C5.3363 14.2012 3.76937 17.67 4.40375 22.2871C6.46128 20.7574 7.15489 17.8282 7.72369 14.7732C8.75776 15.4166 9.30967 16.0861 10.6292 16.1902C15.4949 16.5758 18.2122 11.2134 17.5482 6.26699C16.9583 1.88168 12.6867 -0.349952 8.13815 0.170036Z" fill="white" />
                            </svg>
                        </div>
                        <div className="soci-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path d="M14.9361 0.634766H17.7504L11.6027 7.67906L18.8357 17.2663H13.173L8.73462 11.4529L3.66178 17.2663H0.844848L7.41987 9.72917L0.484375 0.636076H6.29127L10.2971 5.94879L14.9361 0.634766ZM13.9464 15.578H15.5063L5.43924 2.23527H3.76665L13.9464 15.578Z" fill="white" />
                            </svg>
                        </div>
                        <div className="soci-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M21.1448 13.4785V21.5939H16.6487V14.0223C16.6487 12.1212 15.9995 10.8229 14.3716 10.8229C13.1293 10.8229 12.3912 11.697 12.0653 12.5433C11.947 12.8458 11.9164 13.2658 11.9164 13.69V21.5936H7.41994C7.41994 21.5936 7.48029 8.7698 7.41994 7.44236H11.9168V9.44773C11.9077 9.46352 11.895 9.47896 11.8869 9.49405H11.9168V9.44773C12.5143 8.48557 13.5799 7.11007 15.969 7.11007C18.9271 7.11007 21.1448 9.13262 21.1448 13.4785ZM2.71621 0.620972C1.17814 0.620972 0.171875 1.67752 0.171875 3.06566C0.171875 4.42432 1.14897 5.51139 2.65719 5.51139H2.68637C4.25461 5.51139 5.22969 4.42432 5.22969 3.06566C5.19985 1.67752 4.25461 0.620972 2.71621 0.620972ZM0.439117 21.5939H4.93395V7.44236H0.439117V21.5939Z" fill="#F1F2F2" />
                            </svg>
                        </div>
                        <div className="soci-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="19" viewBox="0 0 27 19" fill="none">
                                <path d="M26.2245 3.02618C25.9242 1.90008 25.0359 1.0117 23.9057 0.70725C21.8621 0.160889 13.6628 0.160889 13.6628 0.160889C13.6628 0.160889 5.46763 0.160889 3.41987 0.70725C2.29382 1.00754 1.40552 1.89593 1.10106 3.02618C0.554687 5.06987 0.554688 9.33654 0.554688 9.33654C0.554688 9.33654 0.554687 13.6033 1.10106 15.6469C1.40135 16.773 2.28965 17.6614 3.41987 17.9659C5.46763 18.5122 13.6628 18.5122 13.6628 18.5122C13.6628 18.5122 21.8621 18.5122 23.9057 17.9659C25.0318 17.6655 25.9201 16.7772 26.2245 15.6469C26.7709 13.6033 26.7709 9.33654 26.7709 9.33654C26.7709 9.33654 26.7709 5.06987 26.2245 3.02618Z" fill="white" />
                                <path d="M11.0469 13.2701L17.8574 9.33706L11.0469 5.40405V13.2701Z" fill="#FF0000" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            </div> */}

            {/* <div className="bg-orange">
                <Container>
                    <Grid container spacing={2} id="sec-con">
                        <Grid item xs={12} md={4} sx={12} >
                            <div className="sub-box">

                                <div className="imges">
                                    <img src={Hoursupport} style={{}} alt="Hour Support" />

                                </div>
                                <div className="sub-con">
                                    <h6>24 Hours Support</h6>
                                    <p>We support you <br /> all hours of the day.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={12}>
                            <div className="sub-box">
                                <div className="imges">
                                    <img src={Quality} style={{}} alt="Quality" />

                                </div>
                                <div className="sub-con">
                                    <h6>Qualified Assurance</h6>
                                    <p>All cars have a <br /> valid insurance.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={12} >
                            <div className="sub-box">
                                <div className="imges">
                                    <img src={Gps} style={{}} alt="GPS & Cars" />

                                </div>
                                <div className="sub-con">
                                    <h6>GPS on Cars</h6>
                                    <p>All cars are equipped with <br /> GPS navigation system.</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="why-chooseus">
                <Container className="container">
                    <h2 className="section_head text-center">Why choose Us</h2>
                    <Grid container spacing={2} className="cen">
                        <Grid item xs={12} md={4} sx={12} >
                            <div className="sub-box-3">
                                <div className="imges-3">
                                    <img src={Excellent} alt="excellent" />
                                </div>
                                <h4>Excellent Service</h4>
                                <p>At the heart of our service philosophy is a deep commitment to putting our customers first.We
                                    listen,understand.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={12}>
                            <div className="sub-box-3">
                                <div className="imges-3">
                                    <img src={safe_secure} alt="safe_secure" />
                                </div>
                                <h4>Safe & Secure</h4>
                                <p>Our commitment to safety begins with our fleet of vehicles.Regular maintenance checks, stringent
                                    inspections.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={12}>
                            <div className="sub-box-3">
                                <div className="imges-3">
                                    <img src={best_quality} alt={best_quality} />
                                </div>
                                <h4>Best Quality</h4>
                                <p>our commitment to quality begins with our fleet of vehicles. Meticulously maintained and regularly
                                    inspected.</p>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="perfect-ride bg-secondary">
                <Container className="container">
                    <Grid container className="row sec-pad">
                        <Grid item xs={12} md={7} sx={12} className="">
                            <p className="pre_head">PERFECT RIDES</p>
                            <h4 className="section_head">Best Travel Experience</h4>
                            <p className="para">Excellence is not a destination it's a journey. We are committed to continuours
                                improvement,regularly seeking feedback and implementing enhancements to elevate our
                                services.Your experience with us is at the forefront of our evolution.</p>
                            <ul>
                                <li><span><i className="fa fa-star" aria-hidden="true"></i></span> Premium Quality</li>
                                <li><span><i className="fa fa-star" aria-hidden="true"></i></span> Safe Journey</li>
                                <li><span><i className="fa fa-star" aria-hidden="true"></i></span> Well Professional Drivers</li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} md={5} sx={12} className="pos-rel">
                            <div className="empty-back">

                            </div>
                            <div className="car-img">
                                <img src={car} alt="image" />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div> */}
           <Testimonials />
            {/* <div className='explore-sec'>
                <div className='full-con-width'>
                    <div className="sec-head">
                        <div className="tit-head">
                            <h2 className="main-tit">Discover & Explore</h2>
                            <p className="sub-tit">180 Destination, 456 Course.</p>
                        </div>
                        <div className="view-more-sec">
                            <p>View all Golf Destinations</p>
                            <div className="view-icon">
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <path d="M18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33ZM12.75 16.875L20.535 16.875L17.955 14.295C17.52 13.86 17.52 13.14 17.955 12.705C18.18 12.48 18.465 12.375 18.75 12.375C19.035 12.375 19.32 12.48 19.545 12.705L24.045 17.205C24.48 17.64 24.48 18.36 24.045 18.795L19.545 23.295C19.11 23.73 18.39 23.73 17.955 23.295C17.52 22.86 17.52 22.14 17.955 21.705L20.535 19.125L12.75 19.125C12.135 19.125 11.625 18.615 11.625 18C11.625 17.385 12.135 16.875 12.75 16.875Z" fill="#010101" />
                                </svg></span>
                            </div>
                        </div>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5} sx={12} className="rec-1 pad-rgt-five">
                            <div className="card">
                                <div className="overlay"></div>
                                <img src={rectangle1} alt="image" />
                                <div className="card-con">
                                    <div className="top-sec">
                                        <h2>Ireland</h2>
                                        <p>4 Holidays</p>
                                    </div>
                                    <div className="bot-sec">
                                        <p>Price</p>
                                        <h2>On Request</h2>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={7} sx={12} className="pad-lft-five">
                            <Grid item xs={12} md={6} sx={12} className="rec-2">
                                <div className="card">
                                    <div className="overlay"></div>
                                    <img src={rectangle2} alt="image" />
                                    <div className="card-con">
                                        <div className="top-sec">
                                            <h2>Ireland</h2>
                                            <p>4 Holidays</p>
                                        </div>
                                        <div className="bot-sec">
                                            <p>Price</p>
                                            <h2>On Request</h2>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} sx={12} className="rec-3">
                                <div className="card">
                                    <div className="overlay"></div>
                                    <img src={rectangle3} alt="image" />
                                    <div className="card-con">
                                        <div className="top-sec">
                                            <h2>Ireland</h2>
                                            <p>4 Holidays</p>
                                        </div>
                                        <div className="bot-sec">
                                            <p>Price</p>
                                            <h2>On Request</h2>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={7} sx={12} className="rec-4">
                                <div className="card">
                                    <div className="overlay"></div>
                                    <img src={rectangle4} alt="image" />
                                    <div className="card-con">
                                        <div className="top-sec">
                                            <h2>Ireland</h2>
                                            <p>4 Holidays</p>
                                        </div>
                                        <div className="bot-sec">
                                            <p>Price</p>
                                            <h2>On Request</h2>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5} sx={12} className="rec-5">
                                <div className="card">
                                    <div className="overlay"></div>
                                    <img src={rectangle5} alt="image" />
                                    <div className="card-con">
                                        <div className="top-sec">
                                            <h2>Ireland</h2>
                                            <p>4 Holidays</p>
                                        </div>
                                        <div className="bot-sec">
                                            <p>Price</p>
                                            <h2>On Request</h2>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div> */}
            

            <div className='explore-sec'>
                <div className='full-con-width'>
                    <div className="sec-head">
                        <div className="tit-head">
                            <h2 className="main-tit">Discover & Explore</h2>
                            <p className="sub-tit">180 Destination, 456 Course.</p>
                        </div>
                        <div className="view-more-sec">
                            <p>View all Golf Destinations</p>
                            <div className="view-icon">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                        <path d="M18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33ZM12.75 16.875L20.535 16.875L17.955 14.295C17.52 13.86 17.52 13.14 17.955 12.705C18.18 12.48 18.465 12.375 18.75 12.375C19.035 12.375 19.32 12.48 19.545 12.705L24.045 17.205C24.48 17.64 24.48 18.36 24.045 18.795L19.545 23.295C19.11 23.73 18.39 23.73 17.955 23.295C17.52 22.86 17.52 22.14 17.955 21.705L20.535 19.125L12.75 19.125C12.135 19.125 11.625 18.615 11.625 18C11.625 17.385 12.135 16.875 12.75 16.875Z" fill="#010101" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="desktop-view">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={5} className="rec-1 pad-rgt-five">
                                <div className="card">
                                    <div className="overlay"></div>
                                    <img src={rectangle1} alt="image" />
                                    <div className="card-con">
                                        <div className="top-sec">
                                            <h2>Ireland</h2>
                                            <p>4 Holidays</p>
                                        </div>
                                        <div className="bot-sec">
                                            <p>Price</p>
                                            <h2>On Request</h2>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={7} className="pad-lft-five">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} className="rec-2">
                                        <div className="card">
                                            <div className="overlay"></div>
                                            <img src={rectangle2} alt="image" />
                                            <div className="card-con">
                                                <div className="top-sec">
                                                    <h2>Ireland</h2>
                                                    <p>4 Holidays</p>
                                                </div>
                                                <div className="bot-sec">
                                                    <p>Price</p>
                                                    <h2>On Request</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} className="rec-3">
                                        <div className="card">
                                            <div className="overlay"></div>
                                            <img src={rectangle3} alt="image" />
                                            <div className="card-con">
                                                <div className="top-sec">
                                                    <h2>Ireland</h2>
                                                    <p>4 Holidays</p>
                                                </div>
                                                <div className="bot-sec">
                                                    <p>Price</p>
                                                    <h2>On Request</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={7} className="rec-4">
                                        <div className="card">
                                            <div className="overlay"></div>
                                            <img src={rectangle4} alt="image" />
                                            <div className="card-con">
                                                <div className="top-sec">
                                                    <h2>Ireland</h2>
                                                    <p>4 Holidays</p>
                                                </div>
                                                <div className="bot-sec">
                                                    <p>Price</p>
                                                    <h2>On Request</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={5} className="rec-5">
                                        <div className="card">
                                            <div className="overlay"></div>
                                            <img src={rectangle5} alt="image" />
                                            <div className="card-con">
                                                <div className="top-sec">
                                                    <h2>Ireland</h2>
                                                    <p>4 Holidays</p>
                                                </div>
                                                <div className="bot-sec">
                                                    <p>Price</p>
                                                    <h2>On Request</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="mobile-view">
                        <Slider {...sliderSettings}>
                            <div>
                                <div className="card">
                                    <div className="overlay"></div>
                                    <img src={rectangle1} alt="image" />
                                    <div className="card-con">
                                        <div className="top-sec">
                                            <h2>Ireland</h2>
                                            <p>4 Holidays</p>
                                        </div>
                                        <div className="bot-sec">
                                            <p>Price</p>
                                            <h2>On Request</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="card">
                                    <div className="overlay"></div>
                                    <img src={rectangle2} alt="image" />
                                    <div className="card-con">
                                        <div className="top-sec">
                                            <h2>Ireland</h2>
                                            <p>4 Holidays</p>
                                        </div>
                                        <div className="bot-sec">
                                            <p>Price</p>
                                            <h2>On Request</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="card">
                                    <div className="overlay"></div>
                                    <img src={rectangle3} alt="image" />
                                    <div className="card-con">
                                        <div className="top-sec">
                                            <h2>Ireland</h2>
                                            <p>4 Holidays</p>
                                        </div>
                                        <div className="bot-sec">
                                            <p>Price</p>
                                            <h2>On Request</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="card">
                                    <div className="overlay"></div>
                                    <img src={rectangle4} alt="image" />
                                    <div className="card-con">
                                        <div className="top-sec">
                                            <h2>Ireland</h2>
                                            <p>4 Holidays</p>
                                        </div>
                                        <div className="bot-sec">
                                            <p>Price</p>
                                            <h2>On Request</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="card">
                                    <div className="overlay"></div>
                                    <img src={rectangle5} alt="image" />
                                    <div className="card-con">
                                        <div className="top-sec">
                                            <h2>Ireland</h2>
                                            <p>4 Holidays</p>
                                        </div>
                                        <div className="bot-sec">
                                            <p>Price</p>
                                            <h2>On Request</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>








            <div className="why-choose-sec">
                <div className='full-con-width'>
                    <h2 class="main-tit">Why Choose Us</h2>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={1} className='choose-boxes'>
                            <Grid xs={3.7} className="choose-box">
                                    <div className="choose-img">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 77 77" fill="none">
                                            <g clip-path="url(#clip0_1029_11340)">
                                                <path d="M49.6966 30.8963C49.1832 23.1322 46.392 15.5284 41.2266 9.20801C39.9111 7.60384 37.5049 7.60384 36.1895 9.20801C30.8957 15.5605 27.9441 23.1643 27.3024 30.8963C31.4091 33.078 35.1949 35.9013 38.4995 39.3343C41.8041 35.9334 45.5899 33.1101 49.6966 30.8963ZM28.8424 39.3984C28.3932 39.0776 27.8799 38.7888 27.3986 38.468C27.8799 38.8209 28.3932 39.0776 28.8424 39.3984ZM49.4399 38.5963C49.0228 38.8851 48.5736 39.1097 48.1566 39.4305C48.5736 39.1097 49.0228 38.8851 49.4399 38.5963ZM38.4995 49.5688C32.2432 40.0401 22.0086 33.4309 10.1699 32.2759C8.11656 32.0834 6.41614 33.7838 6.60864 35.8372C8.05239 51.2372 18.3191 64.0063 32.2111 68.9793C34.2324 69.7172 36.3499 70.2626 38.5316 70.6155C40.7132 70.2305 42.7986 69.6851 44.852 68.9793C58.744 64.0063 69.0107 51.2693 70.4545 35.8372C70.647 33.7838 68.9145 32.0834 66.8932 32.2759C54.9903 33.4309 44.7557 40.0401 38.4995 49.5688Z" fill="#598B28" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1029_11340">
                                                    <rect width="77" height="77" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div className="choose-content">
                                        <h4>Heading</h4>
                                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC</p>
                                    </div>
                            </Grid>
                            <Grid xs={3.7} className="choose-box">
                                <div className="choose-img">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 71 71" fill="none">
                                        <path d="M33.4887 8.875H15.0583C9.17122 8.875 6.21289 11.8629 6.21289 17.8092V65.0833H22.1879V53.9896C22.1879 52.7767 23.1937 51.7708 24.4066 51.7708C25.6196 51.7708 26.6254 52.7471 26.6254 53.9896V65.0833H42.3046V17.8092C42.3046 11.8629 39.3758 8.875 33.4887 8.875ZM31.8025 37.7188H17.1587C15.9458 37.7188 14.94 36.7129 14.94 35.5C14.94 34.2871 15.9458 33.2813 17.1587 33.2813H31.8025C33.0154 33.2813 34.0212 34.2871 34.0212 35.5C34.0212 36.7129 33.0154 37.7188 31.8025 37.7188ZM31.8025 26.625H17.1587C15.9458 26.625 14.94 25.6192 14.94 24.4063C14.94 23.1933 15.9458 22.1875 17.1587 22.1875H31.8025C33.0154 22.1875 34.0212 23.1933 34.0212 24.4063C34.0212 25.6192 33.0154 26.625 31.8025 26.625Z" fill="#598B28" />
                                        <path d="M68.0423 62.8645H61.3269V53.9895C64.1373 53.0724 66.1786 50.4395 66.1786 47.3332V41.4166C66.1786 37.5411 63.0131 34.3757 59.1377 34.3757C55.2623 34.3757 52.0969 37.5411 52.0969 41.4166V47.3332C52.0969 50.4099 54.1086 53.0132 56.8598 53.9599V62.8645H2.95898C1.74607 62.8645 0.740234 63.8703 0.740234 65.0832C0.740234 66.2961 1.74607 67.302 2.95898 67.302H58.9602C59.0194 67.302 59.049 67.3316 59.1081 67.3316C59.1673 67.3316 59.1969 67.302 59.2561 67.302H68.0423C69.2552 67.302 70.2611 66.2961 70.2611 65.0832C70.2611 63.8703 69.2552 62.8645 68.0423 62.8645Z" fill="#598B28" />
                                    </svg>
                                </div>
                                    <div className="choose-content">
                                        <h4>Heading</h4>
                                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected</p>
                                    </div>
                            </Grid>
                            <Grid xs={3.7} className="choose-box">
                                    <div className="choose-img">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 71 71" fill="none">
                                            <path d="M33.4887 8.875H15.0583C9.17122 8.875 6.21289 11.8629 6.21289 17.8092V65.0833H22.1879V53.9896C22.1879 52.7767 23.1937 51.7708 24.4066 51.7708C25.6196 51.7708 26.6254 52.7471 26.6254 53.9896V65.0833H42.3046V17.8092C42.3046 11.8629 39.3758 8.875 33.4887 8.875ZM31.8025 37.7188H17.1587C15.9458 37.7188 14.94 36.7129 14.94 35.5C14.94 34.2871 15.9458 33.2813 17.1587 33.2813H31.8025C33.0154 33.2813 34.0212 34.2871 34.0212 35.5C34.0212 36.7129 33.0154 37.7188 31.8025 37.7188ZM31.8025 26.625H17.1587C15.9458 26.625 14.94 25.6192 14.94 24.4063C14.94 23.1933 15.9458 22.1875 17.1587 22.1875H31.8025C33.0154 22.1875 34.0212 23.1933 34.0212 24.4063C34.0212 25.6192 33.0154 26.625 31.8025 26.625Z" fill="#598B28" />
                                            <path d="M68.0423 62.8645H61.3269V53.9895C64.1373 53.0724 66.1786 50.4395 66.1786 47.3332V41.4166C66.1786 37.5411 63.0131 34.3757 59.1377 34.3757C55.2623 34.3757 52.0969 37.5411 52.0969 41.4166V47.3332C52.0969 50.4099 54.1086 53.0132 56.8598 53.9599V62.8645H2.95898C1.74607 62.8645 0.740234 63.8703 0.740234 65.0832C0.740234 66.2961 1.74607 67.302 2.95898 67.302H58.9602C59.0194 67.302 59.049 67.3316 59.1081 67.3316C59.1673 67.3316 59.1969 67.302 59.2561 67.302H68.0423C69.2552 67.302 70.2611 66.2961 70.2611 65.0832C70.2611 63.8703 69.2552 62.8645 68.0423 62.8645Z" fill="#598B28" />
                                        </svg>
                                    </div>
                                    <div className="choose-content">
                                        <h4>Heading</h4>
                                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected</p>
                                    </div>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
            <div className='event-offer-sec'>
                <div className='full-con-width'>
                    <div className="sec-head">
                        <div className="tit-head">
                            <h2 className="main-tit">Special Golf Events & Offers</h2>
                            <p className="sub-tit">Featured Golf Holidays</p>
                        </div>
                        <div className="view-more-sec">
                            <p>View all Golf Destinations</p>
                            <div className="view-icon">
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <path d="M18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33ZM12.75 16.875L20.535 16.875L17.955 14.295C17.52 13.86 17.52 13.14 17.955 12.705C18.18 12.48 18.465 12.375 18.75 12.375C19.035 12.375 19.32 12.48 19.545 12.705L24.045 17.205C24.48 17.64 24.48 18.36 24.045 18.795L19.545 23.295C19.11 23.73 18.39 23.73 17.955 23.295C17.52 22.86 17.52 22.14 17.955 21.705L20.535 19.125L12.75 19.125C12.135 19.125 11.625 18.615 11.625 18C11.625 17.385 12.135 16.875 12.75 16.875Z" fill="#010101" />
                                </svg></span>
                            </div>
                        </div>
                    </div>
                    <div className='eventoffer-boxes'>
                        <Slider {...eventsettings}>
                            <div className="event-box">
                                <div className="event-sec-img">
                                    <img src={eventimg1} alt="image" />
                                </div>
                                <div className="event-sec-content">
                                    <div className="eve-lft-sec">
                                        <h4 className="event-name">Sotavento Golf Trophy</h4>
                                        <p className="event-duration">5 Days/ 4 Nights</p>
                                    </div>
                                    <div className="eve-rgt-sec">
                                        <p className="event-price-tit">From</p>
                                        <p className="event-price">USD 894.95</p>
                                    </div>
                                </div>
                            </div>
                            <div className="event-box">
                                <div className="event-sec-img">
                                    <img src={eventimg2} alt="image" />
                                </div>
                                <div className="event-sec-content">
                                    <div className="eve-lft-sec">
                                        <h4 className="event-name">Sotavento Golf Trophy</h4>
                                        <p className="event-duration">5 Days/ 4 Nights</p>
                                    </div>
                                    <div className="eve-rgt-sec">
                                        <p className="event-price-tit">From</p>
                                        <p className="event-price">USD 894.95</p>
                                    </div>
                                </div>
                            </div>
                            <div className="event-box">
                                <div className="event-sec-img">
                                    <img src={eventimg3} alt="image" />
                                </div>
                                <div className="event-sec-content">
                                    <div className="eve-lft-sec">
                                        <h4 className="event-name">Sotavento Golf Trophy</h4>
                                        <p className="event-duration">5 Days/ 4 Nights</p>
                                    </div>
                                    <div className="eve-rgt-sec">
                                        <p className="event-price-tit">From</p>
                                        <p className="event-price">USD 894.95</p>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
            <HomeBanner />
            <div className='hotdeal-sec'>
                <div className='full-con-width'>
                    <div className="sec-head">
                        <div className="tit-head">
                            <h2 className="main-tit">Discover & Explore Hot Deals</h2>
                            <p className="sub-tit">Get the best out of your Golf Holiday Experience when you book with us.</p>
                        </div>
                        <div className="view-more-sec">
                            <p>View all Golf Destinations</p>
                            <div className="view-icon">
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <path d="M18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33ZM12.75 16.875L20.535 16.875L17.955 14.295C17.52 13.86 17.52 13.14 17.955 12.705C18.18 12.48 18.465 12.375 18.75 12.375C19.035 12.375 19.32 12.48 19.545 12.705L24.045 17.205C24.48 17.64 24.48 18.36 24.045 18.795L19.545 23.295C19.11 23.73 18.39 23.73 17.955 23.295C17.52 22.86 17.52 22.14 17.955 21.705L20.535 19.125L12.75 19.125C12.135 19.125 11.625 18.615 11.625 18C11.625 17.385 12.135 16.875 12.75 16.875Z" fill="#010101" />
                                </svg></span>
                            </div>
                        </div>
                    </div>
                    <div className='eventoffer-boxes'>
                        <Slider {...eventsettings}>
                            <div className="event-box">
                                <div class="event-sec-img">
                                    <img src={dis1} alt="image" />
                                </div>
                                <div class="event-sec-content">
                                    <div class="eve-lft-sec">
                                        <h4 class="event-name">Sotavento Golf Trophy</h4>
                                        <p class="event-duration">5 Days/ 4 Nights</p>
                                    </div>
                                    <div class="eve-rgt-sec">
                                        <p class="event-price-tit">From</p>
                                        <p class="event-price">USD 894.95</p>
                                    </div>
                                </div>
                            </div>
                            <div className="event-box">
                                <div class="event-sec-img">
                                    <img src={dis2} alt="image" />
                                </div>
                                <div class="event-sec-content">
                                    <div class="eve-lft-sec">
                                        <h4 class="event-name">Sotavento Golf Trophy</h4>
                                        <p class="event-duration">5 Days/ 4 Nights</p>
                                    </div>
                                    <div class="eve-rgt-sec">
                                        <p class="event-price-tit">From</p>
                                        <p class="event-price">USD 894.95</p>
                                    </div>
                                </div>
                            </div>
                            <div className="event-box">
                                <div class="event-sec-img">
                                    <img src={dis3} alt="image" />
                                </div>
                                <div class="event-sec-content">
                                    <div class="eve-lft-sec">
                                        <h4 class="event-name">Sotavento Golf Trophy</h4>
                                        <p class="event-duration">5 Days/ 4 Nights</p>
                                    </div>
                                    <div class="eve-rgt-sec">
                                        <p class="event-price-tit">From</p>
                                        <p class="event-price">USD 894.95</p>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
            <HomeGolfHolidays />
            <Footer />
        </div>
    )
}

export default Home
