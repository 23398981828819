import React from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import GolfExperiencesDetails from './GolfExperiencesDetails';

const SearchDetailsPage = () => {

    return (
        <Layout>
            <GolfExperiencesDetails count={5}/>
        </Layout>
    )
}

export default SearchDetailsPage;
