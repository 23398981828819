import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Button } from '@mui/material'
import holi1 from '../../assets/images/wh-1.png'
import holi2 from '../../assets/images/holi2.png'
import holi3 from '../../assets/images/holi3.png'
import holi4 from '../../assets/images/holi4.png'
import blackvideo1 from '../../assets/images/-d98d-4e48-ae76-c5821603c9e3.mp4'
import axios from 'axios';
import { API_URL, BASE_URL } from '../../config/Constants'
import { useLocation, useNavigate } from 'react-router-dom'
const Index = () => {

    const navigate = useNavigate();
    const [selectedButton, setSelectedButton] = useState('golf-holidays');
    const [selectedslug, setSelectedSlug] = useState('golf-holidays');
    const [golfContents, setGolfcontents] = useState([]);

    const Navigate = (slug) => {
        navigate('/'+selectedslug)
        // switch (selectedButton) {
        //     case 'golf-holidays':
        //         navigate('/golf-holidays')
        //     case 'golf-courses':
        //         navigate('/golf-courses')
        //     // case 'Golf Lessons':
        //     //     return holi3;
        //     default:
        //         return 0;
        // }
      };
      
    const handleButtonClick = (slug) => {
     
        setSelectedButton(slug);
        setSelectedSlug(slug);
    };
    useEffect(() => {
        GetGolfContent(); 
      }, [])
      const GetGolfContent = () => {
      
          const payload = ["golf-holidays","golf-courses"];
          axios(API_URL+'/get-single-page',{
              method: 'POST',
              data: {slug:payload},
              headers: {
            'Content-Type': 'application/json',
             'Access-Control-Allow-Origin':'*'
             }
            })
              .then(function(response) {
                  // return response.json()
                  console.log("GolfContents",response.data)
                  setGolfcontents(response.data);
                //   alert(golfContents[0].body);
                }).then(function(body) {
                  console.log(body);
                });
            }
    const getContent = () => {
       
        switch (selectedButton) {
            case 'golf-holidays':
                // return (                   
                //     setTimeout(golfContents[0].body, 5000)                                             
                // );
                if(golfContents.length >0 ){
                    return golfContents[0].body;
                }
            case 'golf-courses':
                // return (                   
                //     setTimeout(golfContents[1].body, 5000)                       
                // );
                if(golfContents.length >0 ){
                    return golfContents[1].body;
                }
            default:
            case 'golf-holidays':
                // return (   
                //     setTimeout(golfContents[0].body, 5000)     
                // );
                if(golfContents.length >0 ){
                    return golfContents[0].body;
                }
        }
    };

    const getImage = () => {
        switch (selectedButton) {
            case 'golf-holidays':
                return holi1;
            case 'golf-courses':
                return holi2;
            // case 'Golf Lessons':
            //     return holi3;
            default:
                return holi4;
        }
    };

    const getButtonClass = (button) => {
        return selectedButton === button ? 'with-wh' : 'without-wh';
    };
    return (
    <div className='golfholiday-sec'>
        <div className="video-em-div">
        <video src={blackvideo1} className='black-video' autoPlay="true" loop muted playsinline></video>
        </div>
<div className="full-d-flex">
    <div className='full-con-width'>
        <Grid container spacing={2} className='golf-mar-top'>
            <Grid item xs={12} md={3} className="button-boxes">
                {
               golfContents.length >0 ?  golfContents.map((golfs,index) => (
                    <Button
                    className={getButtonClass(golfs.slug)}
                    onClick={() => handleButtonClick(golfs.slug)}
                >
                   <div className='icon-ho'>
                        <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="34" viewBox="0 0 32 34" fill="none">
                            <g clip-path="url(#clip0_2295_29378)">
                                <path d="M16.4884 25.7266C20.6707 25.7233 24.151 26.7026 27.4754 28.3427C28.6884 28.9446 29.8641 29.6221 30.996 30.3714C31.993 31.0278 32.2752 32.1671 31.7108 33.2201C31.4001 33.7998 30.9658 33.9763 30.3568 33.757C29.709 33.5238 29.0636 33.2838 28.419 33.0414C27.3999 32.6414 26.2718 32.644 25.2545 33.0485C24.7758 33.2333 24.2821 33.3807 23.8119 33.5853C22.463 34.1723 21.1367 34.0989 19.8083 33.5151C19.3759 33.3251 18.9262 33.1754 18.4815 33.0157C17.4822 32.6432 16.385 32.6524 15.3918 33.0414C14.7985 33.2672 14.1996 33.4778 13.6057 33.702C12.5737 34.1051 11.4317 34.0987 10.4041 33.684C9.82472 33.4592 9.23854 33.2528 8.65634 33.0356C7.64854 32.6463 6.53667 32.646 5.52867 33.0347C4.87056 33.28 4.2119 33.5237 3.55083 33.7603C2.9679 33.969 2.57006 33.8139 2.25223 33.2688C1.65207 32.2395 1.90382 31.0562 2.88774 30.4054C6.19431 28.2183 9.73833 26.6274 13.6497 25.9814C14.5888 25.8275 15.5374 25.7423 16.4884 25.7266Z" fill="white" />
                                <path d="M7.65625 8.95087C7.656 6.1123 8.246 3.30614 9.38707 0.718765C9.52566 0.402201 9.62794 0.377383 9.89368 0.586998C13.0708 3.09317 15.3007 6.28931 16.5834 10.1754C16.7583 10.708 16.3269 11.4011 15.6985 11.5958C15.1185 11.7755 14.5362 11.9523 13.9462 12.0913C13.6621 12.1582 13.6162 12.2575 13.6864 12.5393C14.6632 16.4541 15.6232 20.3733 16.6038 24.2871C16.6918 24.6387 16.6444 24.7301 16.2839 24.725C15.7979 24.7211 15.3122 24.753 14.8306 24.8202C14.5621 24.8561 14.4837 24.7667 14.4237 24.5197C13.9001 22.3646 13.3639 20.2127 12.8317 18.0598C12.4252 16.4153 12.0096 14.7731 11.6203 13.1243C11.5389 12.7797 11.4117 12.7314 11.0975 12.8238C10.554 12.9837 9.99686 13.0967 9.44306 13.2178C8.64191 13.3931 8.02522 12.9461 7.90646 12.1269C7.74322 11.0764 7.65957 10.0146 7.65625 8.95087Z" fill="white" />
                                <path d="M11.4219 0.0759211C12.2515 -0.0307678 13.0914 -0.0235173 13.9191 0.0974809C17.9406 0.631513 21.1201 2.58818 23.4252 6.01687C23.5466 6.19125 23.6313 6.38951 23.674 6.59911C23.7167 6.8087 23.7164 7.02508 23.6732 7.23457C23.6463 7.38383 23.6067 7.53158 23.5909 7.682C23.49 8.64338 22.9925 9.21005 22.069 9.47921C21.0857 9.76579 20.135 10.1675 19.1629 10.4967C18.5025 10.7204 18.0119 10.4574 17.8022 9.80103C16.5991 6.0362 14.5125 2.87545 11.5926 0.283646C11.542 0.238784 11.4916 0.193781 11.4428 0.146946C11.4344 0.138877 11.4347 0.121198 11.4219 0.0759211Z" fill="white" />
                                <path d="M7.84778 0.980713C7.53228 1.82441 7.26002 2.68438 7.03211 3.5571C6.27487 6.54972 6.1636 9.67516 6.70605 12.7162C6.80693 13.2829 6.53098 13.791 5.98537 13.907C4.70412 14.1794 3.40887 14.3728 2.11133 14.5466C2.01699 14.5537 1.92213 14.5474 1.82948 14.5279C0.966551 14.3931 -0.0857034 12.9645 0.00554929 12.0785C0.43899 7.87203 2.37816 4.57982 5.74889 2.15555C6.40616 1.68946 7.10969 1.29566 7.84778 0.980713Z" fill="white" />
                                <path d="M29.3704 17.6528C29.3683 18.4694 29.1297 19.2669 28.6847 19.9447C28.2397 20.6225 27.6084 21.1502 26.8704 21.4611C26.1325 21.7719 25.321 21.852 24.5386 21.6912C23.7562 21.5303 23.0379 21.1359 22.4745 20.5575C21.9111 19.9792 21.5279 19.243 21.3733 18.442C21.2187 17.6409 21.2997 16.8109 21.6059 16.0569C21.9122 15.3029 22.43 14.6587 23.094 14.2057C23.7579 13.7527 24.5382 13.5112 25.3363 13.5117C25.8671 13.5121 26.3926 13.6195 26.8827 13.8279C27.3729 14.0364 27.8181 14.3416 28.1928 14.7263C28.5675 15.1109 28.8644 15.5674 29.0664 16.0696C29.2685 16.5717 29.3718 17.1098 29.3704 17.6528Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2295_29378">
                                    <rect width="32" height="34" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        </span>
                    </div>
                    {golfs.name}
                      </Button>
                )) : ''
            }
              
            </Grid>
            <Grid item xs={12} md={9} className='responsive-holiday'>
                <div className="button-lft-rgt">
                    <Button className="without-wh">
                        <span>
                            <img src={getImage()} alt={selectedButton} />
                        </span>
                        {selectedButton.replace('-',' ')}
                    </Button>
                  
                    <div className="read--more">
                        <p><span style={{ "cursor": 'pointer' }} onClick={() => Navigate()}>Read More</span></p>
                        <div className="re-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                <path d="M18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33ZM12.75 16.875L20.535 16.875L17.955 14.295C17.52 13.86 17.52 13.14 17.955 12.705C18.18 12.48 18.465 12.375 18.75 12.375C19.035 12.375 19.32 12.48 19.545 12.705L24.045 17.205C24.48 17.64 24.48 18.36 24.045 18.795L19.545 23.295C19.11 23.73 18.39 23.73 17.955 23.295C17.52 22.86 17.52 22.14 17.955 21.705L20.535 19.125L12.75 19.125C12.135 19.125 11.625 18.615 11.625 18C11.625 17.385 12.135 16.875 12.75 16.875Z" fill="white" />
                            </svg>
                        </div>
                    </div>
                  
                </div>
                <p className='holiday-para'> {getContent()} </p>
            </Grid>
        </Grid>
        </div>
        </div>
    </div>
    )
}
export default Index;