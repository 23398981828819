import React, { useState, useEffect } from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import GolfEvents from './GolfEvents';

const GolfEventsList = () => {
     const [details, setDetails] = useState({'title':'Golftripz Events','meta_desc':'Golftripz Events','meta_keyword':'golftripz , events'})
    return (
        <Layout head={"GolfEvents List"} details={details}>
            <GolfEvents/>
        </Layout>
    )
}

export default GolfEventsList;
