import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Button } from '@mui/material'
import holi1 from '../../assets/images/wh-1.png'
import holi2 from '../../assets/images/holi2.png'
import holi3 from '../../assets/images/holi3.png'
import holi4 from '../../assets/images/holi4.png'
import testhd1 from '../../assets/images/testhd (1).png'
import testhd2 from '../../assets/images/testhd (2).png'
import testhd3 from '../../assets/images/testhd (3).png'
import testhd4 from '../../assets/images/testhd (4).png'
import blackvideo1 from '../../assets/images/-d98d-4e48-ae76-c5821603c9e3.mp4'
import axios from 'axios';
import { API_URL, BASE_URL } from '../../config/Constants'
import { useLocation, useNavigate } from 'react-router-dom'
import Slider from "react-slick";
import moment from 'moment';




const Index = () => {

    const images = [
        { src: testhd1, href: 'https://www.trustpilot.com/review/www.golftripz.com' },
        { src: testhd2, href: 'https://www.trustpilot.com/review/www.golftripz.com' },
        { src: testhd3, href: 'https://www.trustpilot.com/review/www.golftripz.com' },
        { src: testhd2, href: 'https://www.trustpilot.com/review/www.golftripz.com' },
        { src: testhd4, href: 'https://www.trustpilot.com/review/www.golftripz.com' }
      ];
    

    const handleImageClick = (href) => {
      
         window.open(href,'_blank');
      };
      

    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        GetTestimonials(); 
      }, [])

      const GetTestimonials = () => {
       
        axios(API_URL+'/testimonial',{
            method: 'GET',
            headers: {
             // 'Authorization': `bearer ${token}`,
             'Content-Type': 'application/json',
             'Access-Control-Allow-Origin':'*'
           }
          })
            .then(function(response) {
             
              setTestimonials(response.data.data);
              }).then(function(body) {
                console.log(body);
              });
      }

       const GetshortName = (name) => {
        let splittedName = name.split(' ');
        var name = ''
        splittedName.forEach(n => {
          name += n[0]
        })
        return name;
       }

       var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow:3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="testimonial-sec">
            <div className='full-con-width'>
                <div className="sec-head">
                    <div className="tit-head">
                        <h2 className="main-tit">Testimonials</h2>
                        <p className="sub-tit">What our customers says about us</p>
                    </div>
                    <div className="view-more-sec">
                        <p>View all Reviews</p>
                        <div className="view-icon">
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                <path d="M18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33ZM12.75 16.875L20.535 16.875L17.955 14.295C17.52 13.86 17.52 13.14 17.955 12.705C18.18 12.48 18.465 12.375 18.75 12.375C19.035 12.375 19.32 12.48 19.545 12.705L24.045 17.205C24.48 17.64 24.48 18.36 24.045 18.795L19.545 23.295C19.11 23.73 18.39 23.73 17.955 23.295C17.52 22.86 17.52 22.14 17.955 21.705L20.535 19.125L12.75 19.125C12.135 19.125 11.625 18.615 11.625 18C11.625 17.385 12.135 16.875 12.75 16.875Z" fill="#010101" />
                            </svg></span>
                        </div>
                    </div>
                </div>
                <div className="testmonial-boxes">
                    <Slider {...settings}>
                        {testimonials.map((image, index) => (
                            <div key={index}  className='test-bx-img' onClick={() => handleImageClick(image.url)}>
                                <img style={{ "cursor": 'pointer' }} src={image.image} alt={`image-${index}`} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}
export default Index;