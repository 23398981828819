import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Button } from '@mui/material'

import axios from 'axios';
import { API_URL, BASE_URL } from '../../config/Constants'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
const ReviewForm = ({ count }) => {

    const { user } = useSelector(state => state.auth)
    const { selectedCourse, bookingData } = useSelector(state => state.booking)
    const [reviewText, setReviewText] = useState('');
    const [activeBox, setActiveBox] = useState(null);
    const handleBoxClick = (index) => {
        setActiveBox(index);
    };

    const handleSubmit = () => {
       
        // if (activeBox !== null) {
        //     alert(`You have selected review score: ${activeBox}`);
        // } else {
        //     alert('Please select a review score before submitting.');
        // }

        // if (reviewText.trim()) {
        //     alert(`Review submitted: ${reviewText}`);
        //     setReviewText('');
        // } else {
        //     alert('Please enter a review before submitting.');
        // }
        if(!user){
            alert("Please Login to Review");
        }else{
            console.log(user)
            var payload ={    
                name:user.name,
                contact_no:user.mobile,
                email:user.email,
                rating:activeBox,
                event_id:selectedCourse.golf_courses.id,
                user_id:user.id,
                type_id:3,
                message:reviewText,
            }
            axios(API_URL+'/add-testimonial',{
                method: 'POST',
                data: payload,
                headers: {
                 // 'Authorization': `bearer ${token}`,
                 'Content-Type': 'application/json'
               }
              })
                .then(function(response) {
                    // return response.json()
                  //   console.log(response.data)
                  toast.success(response.data.message)
                  //   alert(golfContents[0].body);
                  }).then(function(body) {
                    console.log(body);
                  });
        }
    };
    const handleInputChange = (e) => {
        if(!user){
            alert("Please Login to Review");
        }else{
            setReviewText(e.target.value);
        }
        
    };

    return (
        <>
        <div className="detailsinfo-con review-box-info">
        <h4 className="details-cont-tabhead">Write a Review</h4>
        <div className='details-box-review-txt'>
            <textarea placeholder="Enter your review here..."
                value={reviewText}
                onChange={handleInputChange} class="review-input" name="postContent"></textarea>
        </div>
    </div>
    <div className="detailsinfo-con review-box-info">
        <h4 className="details-cont-tabhead">Review Score</h4>
        <div className="num-recon">
            <div className="num-review-boxes">
                {[...Array(count)].map((_, index) => (
                    <div
                        key={index}
                        className={`review-count-box ${index + 1 === activeBox ? 'rebtn-active' : ''}`}
                        onClick={() => handleBoxClick(index + 1)}
                    >
                        {index + 1}
                    </div>
                ))}
            </div>
            <div className="num-re-sub">
                <button className="re-submit-btn" onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    </div>
    </>
    )
}
export default ReviewForm;