import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Payment.scss';
import paymentimg from '../../assets/images/Payment by tapping card over payment terminal.png';
import paymenttrip from '../../assets/images/Rectangle 13132.png';

const PaymentPageDetails = ({ pageContents, loading }) => {

  const PassangerDetailsPage = () => {
    setAnchorElNav(null);
    navigation('/PassangerDetailsPage')
  };
  const SuccessPage = () => {
    setAnchorElNav(null);
    navigation('/SuccessPage')
  };

  const navigation = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const { user } = useSelector(state => state.auth);


  const [startDate, setStartDate] = useState(new Date());
  const [selectedTab, setSelectedTab] = useState('credit');
  const [formValues, setFormValues] = useState({
    name: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    details: '',
    couponCode: ''
  });
  const [formErrors, setFormErrors] = useState({});

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setFormErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const validateForm = () => {
    let errors = {};
    if ((selectedTab === 'credit' || selectedTab === 'debit') && !formValues.name) {
      errors.name = 'Name on the card is required';
    }
    if ((selectedTab === 'credit' || selectedTab === 'debit') && !formValues.cardNumber) {
      errors.cardNumber = 'Card number is required';
    }
    if ((selectedTab === 'credit' || selectedTab === 'debit') && !formValues.expiryDate) {
      errors.expiryDate = 'Expiry date is required';
    }
    if ((selectedTab === 'credit' || selectedTab === 'debit') && !formValues.cvv) {
      errors.cvv = 'CVV number is required';
    }
    if (selectedTab !== 'credit' && selectedTab !== 'debit' && !formValues.details) {
      errors.details = `Details for ${selectedTab} are required`;
    }
    if (selectedTab !== 'credit' && selectedTab !== 'debit' && !formValues.couponCode) {
      errors.couponCode = `Details for ${selectedTab} are required`;
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      // Proceed with form submission
      console.log('Form submitted:', formValues);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <div className='listitem'>
      <div className='paymentpage-container'>
        <Grid container spacing={2} className='paymentpage-drow'>
          <Grid xs={6} className='max-width-full'>
          <h2>Payment Details</h2>
            <div className="tab-menu">
              {['credit', 'debit', 'netbanking', 'other'].map((tab) => (
                <button
                  key={tab}
                  className={selectedTab === tab ? 'tab active' : 'tab'}
                  onClick={() => handleTabChange(tab)}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
            <div className="payment-content">
              <div className='payment-lft-img'>
                <div className='payment-lft-imgcontent'>
                  <h5><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                    <path d="M12.0001 17.3498C12.9003 17.3498 13.6301 16.6201 13.6301 15.7198C13.6301 14.8196 12.9003 14.0898 12.0001 14.0898C11.0999 14.0898 10.3701 14.8196 10.3701 15.7198C10.3701 16.6201 11.0999 17.3498 12.0001 17.3498Z" fill="#292D32" />
                    <path d="M18.28 9.53V8.28C18.28 5.58 17.63 2 12 2C6.37 2 5.72 5.58 5.72 8.28V9.53C2.92 9.88 2 11.3 2 14.79V16.65C2 20.75 3.25 22 7.35 22H16.65C20.75 22 22 20.75 22 16.65V14.79C22 11.3 21.08 9.88 18.28 9.53ZM12 18.74C10.33 18.74 8.98 17.38 8.98 15.72C8.98 14.05 10.34 12.7 12 12.7C13.66 12.7 15.02 14.06 15.02 15.72C15.02 17.39 13.67 18.74 12 18.74ZM7.35 9.44C7.27 9.44 7.2 9.44 7.12 9.44V8.28C7.12 5.35 7.95 3.4 12 3.4C16.05 3.4 16.88 5.35 16.88 8.28V9.45C16.8 9.45 16.73 9.45 16.65 9.45H7.35V9.44Z" fill="#292D32" />
                  </svg></span>Card is Secure</h5>
                  <p>Your card information is end-to-end encrypted</p>
                </div>
                <div className='payment-imgbox'>
                  <img src={paymentimg} alt="Payment Method" />
                </div>
              </div>
              <div className="payment-form">
                <form onSubmit={handleSubmit}>
                  {selectedTab === 'credit' || selectedTab === 'debit' ? (
                    <>
                      <div className="form-group">
                        <label>Name on the card</label>
                        <input placeholder="Enter name here" type="text" name="name" value={formValues.name} onChange={handleInputChange} />
                        {formErrors.name && <span className="error">{formErrors.name}</span>}
                      </div>
                      <div className="form-group">
                        <label>Card Number</label>
                        <input placeholder="Enter card number here" type="text" name="cardNumber" value={formValues.cardNumber} onChange={handleInputChange} />
                        {formErrors.cardNumber && <span className="error">{formErrors.cardNumber}</span>}
                      </div>
                      <div className='payment-df'>
                        <div className="form-group">
                          <label>Expiry Date</label>
                          <input placeholder="Enter date here" type="text" name="expiryDate" value={formValues.expiryDate} onChange={handleInputChange} />
                          {formErrors.expiryDate && <span className="error">{formErrors.expiryDate}</span>}
                        </div>
                        <div className="form-group">
                          <label>CVV Number</label>
                          <input placeholder="Enter cvv number here" type="text" name="cvv" value={formValues.cvv} onChange={handleInputChange} />
                          {formErrors.cvv && <span className="error">{formErrors.cvv}</span>}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="form-group">
                      <label>Details for {selectedTab}</label>
                      <input type="text" name="details" value={formValues.details} onChange={handleInputChange} />
                      {formErrors.details && <span className="error">{formErrors.details}</span>}
                    </div>
                  )}
                  <div className="form-group coupon-inputform">
                    <label>Coupon Code</label>
                    <input type="text" name="couponCode" value={formValues.couponCode} onChange={handleInputChange} />
                    <button className='couponcode-btn'>Apply</button>
                    {formErrors.couponCode && <span className="error">{formErrors.couponCode}</span>}
                  </div>
                  <button onClick={SuccessPage} type="submit" className="btn-payment"><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                    <path d="M23 8V18C23 19.1 22.1 20 21 20H5C4.45 20 4 19.55 4 19C4 18.45 4.45 18 5 18H21V8C21 7.45 21.45 7 22 7C22.55 7 23 7.45 23 8ZM4 16C2.34 16 1 14.66 1 13V7C1 5.34 2.34 4 4 4H16C17.66 4 19 5.34 19 7V14C19 15.1 18.1 16 17 16H4ZM7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7C8.34 7 7 8.34 7 10Z" fill="white" />
                  </svg></span>Confirm Payment</button>
                  <button type="button" className="btn-back" onClick={PassangerDetailsPage}>Back</button>
                </form>
              </div>
            </div>
          </Grid>
          <Grid xs={5} className='payment-tripsummary-container max-width-full'>
            <div className='payment-tripsum-rgt'>
              <h2 className='payment-tripsum-tit'>Trip Summary</h2>
              <div className='pay-tripsummary-details'>
                <div className='payment-tripsumimg'>
                  <img src={paymenttrip} alt="image" />
                </div>
                <div className='payment-tripsumcont'>
                  <h5>Melbourne 5 Star Hotel</h5>
                  <p className='pay-triphotel'>3 Day/4 Nights Stay at Melbourne</p>
                  <p>Deluxe Twin Bed Room | Apr 10, 11:30am</p>
                  <p>Person Per Unit - 2</p>
                  <p>No of Units - 2 </p>
                  <p className='pay-tripsum-total'><span>Price</span>$40/Per Person</p>
                </div>
              </div>
              <div className='pay-summary-fulltotal'>
                  <h5>Payment Summary</h5>
                  <div>
                    <p>Stay at Melbourne 5 Star Hotel</p>
                    <h5>$120</h5>
                  </div>
                  <div>
                    <p>Platform Fee</p>
                    <h5>$120</h5>
                  </div>
                  <div className='pay-trip-dis'>
                    <p>Discount</p>
                    <h5>$120</h5>
                  </div>
                  <div className='pay-trip-total'>
                    <p>Total Price</p>
                    <h5>$120</h5>
                  </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PaymentPageDetails;
