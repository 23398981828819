import React, { useState, useEffect ,useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants';
import {getGolfResults } from '../../Redux/actions/bookingActions'
import axios from '../../CustomAxios'
import { API_URL } from '../../config/Constants';
import { CLEAR_PREVIOUS_ROUTE } from '../../Redux/constants/userConstants';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
const ModifySearch = () => {
    const location = useLocation();
    var datas = localStorage.getItem("booking");
    const [search, setSearch] = React.useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { previousRoute } = useSelector(state => state.auth);
  
    const handleSubmit =(e)=>{
	
		var data = {
			'search':search,
			'startdate':startDate
		}
		
		e.preventDefault();
		localStorage.setItem("booking", JSON.stringify(data))
		localStorage.setItem("search", JSON.stringify(data))
		// navigate('/SearchResult');
        getGolfCourseData()
	}
    const getGolfCourseData = async() => {
        var datas = localStorage.getItem("booking");	
       
        if(search ===null || search == ''){
           
            var data = {
                'search':"",
                'startdate':new Date()
            }
            localStorage.setItem("booking", JSON.stringify(data))
        
            let bookinData = JSON.parse(localStorage.getItem("booking"));
            dispatch({
                type: BOOKING_DATA,
                payload: bookinData
            })
            await dispatch(getGolfResults(datas));
        }else{
            let bookinData = JSON.parse(localStorage.getItem("search"));
            dispatch({
                type: BOOKING_DATA,
                payload: bookinData
            })
            await  dispatch(getGolfResults(bookinData));
        }
        navigate('/SearchResult')
       
    }
    const handleInputChange = async (event) => {
        const value = event.target.value;
        setInputValue(value);
        setSearch(value)
        if (value.length >= 3) {
          const apidata = {search:value}
          axios(API_URL+'/golf-courses',{
            method: 'POST',
            data: apidata,
            headers: {
             'Content-Type': 'application/json'
           }
          })
            .then(function(response) {
                const {data} = response.data;
               
                if(data?.length > 0){   
                    let resultData = []; 
                    for(let item of data){
                        resultData.push(item?.golf_courses?.golf_course_name)
                    }
                    console.log(resultData)
                    setSuggestions(resultData);
                    setShowSuggestions(true);
                }
                            
              })
        } else {
          setShowSuggestions(false);
        }
      };
      const handleSuggestionClick = (suggestion) => {
       
        setInputValue(suggestion);
        setSearch(suggestion)
        setShowSuggestions(false);
      };

      const handleScroll = useCallback(_.throttle(() => {
        const header = document.querySelector('.home-content');
        const toggleClass = 'is-sticky';
        const currentScroll = window.pageYOffset;
        if (header) {
            if (currentScroll > 100) {
             
                header.classList.add(toggleClass);
            } else {
                header.classList.remove(toggleClass);
            }
        }
    }, 100), []);
      useEffect(() => {
        // Set header data
       
        if (previousRoute && previousRoute === location.pathname) {
            dispatch({ type: CLEAR_PREVIOUS_ROUTE });
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [previousRoute, location.pathname, dispatch, handleScroll]);
    return (
        <form onSubmit={handleSubmit}>
            <div className="home-content">
                <div className="search-box">
                    <div className="search-option">
                        <label className='search-lab' for="#"><span><svg xmlns="http://www.w3.org/2000/svg" width="16"
                            height="16" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M12.0009 13.4304C13.724 13.4304 15.1209 12.0336 15.1209 10.3104C15.1209 8.5873 13.724 7.19043 12.0009 7.19043C10.2777 7.19043 8.88086 8.5873 8.88086 10.3104C8.88086 12.0336 10.2777 13.4304 12.0009 13.4304Z"
                                stroke="white" stroke-width="1.5" />
                            <path
                                d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z"
                                stroke="white" stroke-width="1.5" />
                        </svg></span></label>
                        <input type="text"  onChange={handleInputChange}  value={inputValue} placeholder='Golf Course' />
                        {showSuggestions && suggestions &&(
                            <ul className="suggestions-list" style={{display:'contents'}}>
                            {suggestions.map((suggestion, index) => (
                                <li
                                key={index}
                                onClick={() => handleSuggestionClick(suggestion)}
                                >
                                {suggestion}
                                </li>
                            ))}
                            </ul>
                        )}
                    </div>
                    <div className="date-pick">
                        <label htmlFor="date-picker" id='date-picker'>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                    <path d="M8 2V5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M16 2V5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M3.5 9.08997H20.5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M15.6937 13.7H15.7027" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M15.6937 16.7H15.7027" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M11.9945 13.7H12.0035" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M11.9945 16.7H12.0035" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M8.29529 13.7H8.30427" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M8.29529 16.7H8.30427" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </span>
                        </label>
                        <DatePicker
                            // id="date-picker"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd - MM - yyyy"
                            placeholderText="12 - 04 - 24"
                            minDate={new Date()} 
                        />
                    </div>
                    <div className="search-btn">
                        <button onClick={handleSubmit}>Search</button>
                    </div>
                </div>
                <div className="search-box responsive">
                    <div className="search-option">
                        <input type="text" placeholder='Golf Course | 12-07-24'/>
                    </div>
                    <div className="search-btn">
                        <button><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" fill="white" />
                            <path d="M21.2996 22.0005C21.1196 22.0005 20.9396 21.9305 20.8096 21.8005L18.9496 19.9405C18.6796 19.6705 18.6796 19.2305 18.9496 18.9505C19.2196 18.6805 19.6596 18.6805 19.9396 18.9505L21.7996 20.8105C22.0696 21.0805 22.0696 21.5205 21.7996 21.8005C21.6596 21.9305 21.4796 22.0005 21.2996 22.0005Z" fill="white" />
                        </svg></button>
                    </div>
                </div>
            </div>
            </form>
    )
}

export default ModifySearch;
