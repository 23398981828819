import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import axios from 'axios';
import { API_URL, BASE_URL } from '../../config/Constants'
import filreset from '../../assets/images/lock_reset (1).png'
import Golfholidayimg from '../../assets/images/image 84.png'
import HolidayList from './HolidayList';

const GolfHolidays = () => {

  const dispatch = useDispatch();
  const { availableHolidays } = useSelector(state => state.booking)
  const [golfHolidays, setGolfHolidays] = useState(availableHolidays);
  const [searchdata, setSearchdata] = useState("");
  const navigation = useNavigate();
  const [countries, setCountries] = useState([]);
  const [minimumPrice, setMinimumPrice] = useState(0);
  const [maximumPrice, setMaximumPrice] = useState(0);
  const [filteredminimumPrice, setFilMinimumPrice] = useState(0);
  const [filteredmaximumPrice, setFilMaximumPrice] = useState(0);

  useEffect(() => {
    // Retrieve search data from local storage and parse it
    const searchData = localStorage.getItem("search");
    if (searchData) {
      setSearchdata(JSON.parse(searchData));
    }

    if (availableHolidays) {
      setGolfHolidays(availableHolidays);

      // Find the lowest price among all np_golfer_single prices
      const allPrices = availableHolidays.flatMap(holiday =>
        holiday.holiday_price?.map(item => item.np_golfer_single) || []
      );

      if (allPrices.length > 0) {
        const higestrice = Math.max(...allPrices);
        setMaximumPrice(higestrice + 10);
        setFilMaximumPrice(higestrice + 10);
      }

      // Filter countries (assuming this is a function to update some state)
      filterCountries();
    }
  }, [availableHolidays]);

  const filterCountries = () => {
    var lists = [];
    availableHolidays.forEach((holiday) => {
      if (Array.isArray(holiday?.golf_holidays?.country_id)) {
        holiday?.golf_holidays?.country_id.forEach((country) => {
          if (country.name && !lists.includes(country.name)) {
            lists.push(country.name);
          }
        });
      }
    });

    setCountries(lists)

  }
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const { user } = useSelector(state => state.auth);


  // ----date--------------
  const [startDate, setStartDate] = useState(new Date());

  // tabview

  const [activeTab, setActiveTab] = useState('All');

  const tabs = ['All', 'Customizable','Non-Customizable'];

  // --------------
  const [isOn, setIsOn] = useState(true); // Set initial state to true

  const handleToggle = () => {
    setIsOn(prevState => !prevState);
  };

  const [currentSortOrder, setCurrentSortOrder] = useState('Sort');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSortChange = (value) => {
    if (value === "A to Z") {
      var ortedData = golfHolidays.sort((a, b) => {
        return a.golf_holidays.holiday_name.localeCompare(b.golf_holidays.holiday_name)
      })
    } else {
      var ortedData = golfHolidays.sort((a, b) => {
        return b.golf_holidays.holiday_name.localeCompare(a.golf_holidays.holiday_name)
      })
    }
    setGolfHolidays(ortedData)
    setCurrentSortOrder(value);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  useEffect(() => {
    if (isFilterVisible) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isFilterVisible]);


  const [selectedLocation, setSelectedLocation] = useState('All');
  const [selectedHeading, setSelectedHeading] = useState('All');

  const handleLocationChange = (event) => {
    var value = event.target.value;
    if (value != "All") {
      const filteredData = availableHolidays.filter((item) =>
        item.golf_holidays.country_id.some((country) => country.name === value)
      );
      setGolfHolidays(filteredData);
    } else {
      setGolfHolidays(availableHolidays);
    }
    setSelectedLocation(event.target.value);

  };

  const handleHeadingChange = (event) => {
    setSelectedHeading(event.target.value);
  };
  
  const handleminrange = (event) => {
  const value = parseFloat(event.target.value);
    setFilMinimumPrice(value);

    const filteredResult = availableHolidays.filter(items =>
      items.holiday_price?.some(item =>
        item.np_golfer_single >= value && // Include items with np_golfer_single equal to the minimum value
        item.np_golfer_single <= filteredmaximumPrice // Compare with the current filtered maximum price
      )
    );
    setGolfHolidays(filteredResult);
  }

  const handlemaxrange = (event) => {
    const value = parseFloat(event.target.value);
    setFilMaximumPrice(value);

    const filteredResult = availableHolidays.filter(items =>
      items.holiday_price?.some(item =>
        item.np_golfer_single >= filteredminimumPrice && // Compare with the current filtered minimum price
        item.np_golfer_single <= value // Include items with np_golfer_single equal to the maximum value
      )
    );
    setGolfHolidays(filteredResult);
  }

        
  const resetFilter=()=>{
    setGolfHolidays(availableHolidays)
    setSelectedLocation("All")
    setFilMaximumPrice(maximumPrice)
    setFilMinimumPrice(0)
    setMinimumPrice(0)
    
  }

  const filterCustomizableHolidaysByPriceType = (holidays, desiredPriceType) => {
    console.log(holidays[0])
    return holidays.filter(holiday => holiday?.golf_holidays?.price_type === desiredPriceType);
  };

  return (
    <div className='listitem'>
      <div className='listitems-sec'>
        <Grid container spacing={2} className='list-dis-jus'>
          <Grid item xs={2.8} className={`filter-content-box ${isFilterVisible ? 'active' : ''}`}>
            <div className="filter-header">
              <div className="fil-lft-cont">
                <div className="fil-round">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
                      <path d="M27.4672 5.46647V8.39981C27.4672 9.46647 26.8005 10.7998 26.1338 11.4665L20.4005 16.5331C19.6005 17.1998 19.0672 18.5331 19.0672 19.5998V25.3331C19.0672 26.1331 18.5338 27.1998 17.8672 27.5998L16.0005 28.7998C14.2672 29.8665 11.8672 28.6665 11.8672 26.5331V19.4665C11.8672 18.5331 11.3338 17.3331 10.8005 16.6665L10.1738 16.0131C9.7605 15.5731 9.6805 14.9065 10.0138 14.3865L16.8405 3.42647C17.0805 3.0398 17.5072 2.7998 17.9738 2.7998H24.8005C26.2672 2.7998 27.4672 3.9998 27.4672 5.46647Z" fill="#232323" />
                      <path d="M13.7999 4.83981L9.06654 12.4265C8.6132 13.1598 7.5732 13.2665 6.9732 12.6398L5.7332 11.3331C5.06654 10.6665 4.5332 9.46647 4.5332 8.66647V5.5998C4.5332 3.9998 5.7332 2.7998 7.19987 2.7998H12.6665C13.7065 2.7998 14.3465 3.94647 13.7999 4.83981Z" fill="#232323" />
                    </svg>
                  </span>
                </div>
                <p>Filters</p>
              </div>
              <div className="fil-rgt-cont">
                <div className="fil-reset">
                  <button onClick={()=>resetFilter()}>
                    <span><img src={filreset} alt="reset-btn" /></span>Reset all
                  </button>
                </div>
              </div>
            </div>
            <div className="filter-boxes">
              <div className="filter-box location">
                <h4>Location</h4>
                <div className="radio-button-items">
                  <div className="radio-item">
                    <input
                      type="radio"
                      id="location-all"
                      name="location"
                      value="All"
                      checked={selectedLocation === 'All'}
                      onChange={handleLocationChange}
                    />
                    <label htmlFor="location-all">All</label>
                  </div>
                  {golfHolidays &&
                    countries.map((country, index) => (
                      <div className="radio-item" key={index}>
                        <input
                          type="radio"
                          id={`location-${country}`}
                          name="location"
                          value={country}
                          checked={selectedLocation === country}
                          onChange={handleLocationChange}
                        />
                        <label htmlFor={`location-${country}`}>{country}</label>
                      </div>
                    ))}
                </div>
              </div>
              <div className="filter-box price-range">
              <h4>Price ({minimumPrice} - {maximumPrice})</h4>
                <div className="range-bar">
                  <div class="price-input">
                    <div class="field">
                      <span>Min</span>
                      <input type="number" class="input-min" value={filteredminimumPrice} />
                    </div>
                    <div class="separator">-</div>
                    <div class="field">
                      <span>Max</span>
                      <input type="number" class="input-max" value={filteredmaximumPrice} />
                    </div>
                  </div>

                </div>
                <div className="slider">
                  <div className="progress"></div>
                </div>
                <div className="range-input">
                  <input type="range" onChange={handleminrange} value={filteredminimumPrice} className="range-min" min={minimumPrice} max={maximumPrice} />
                  <input type="range" onChange={handlemaxrange} value={filteredmaximumPrice} className="range-max" min={minimumPrice} max={maximumPrice} />
                </div>
              </div>
              {/* <div className="filter-box heading">
                <h4>Heading</h4>
                <div className="radio-button-items">
                  <div className="radio-item">
                    <input
                      type="radio"
                      id="heading-all"
                      name="heading"
                      value="All"
                      checked={selectedHeading === 'All'}
                      onChange={handleHeadingChange}
                    />
                    <label htmlFor="heading-all">All</label>
                  </div>
                  <div className="radio-item">
                    <input
                      type="radio"
                      id="heading-golf-coast"
                      name="heading"
                      value="England’s Golf Coast"
                      checked={selectedHeading === 'England’s Golf Coast'}
                      onChange={handleHeadingChange}
                    />
                    <label htmlFor="heading-golf-coast">England’s Golf Coast</label>
                  </div>
                  <div className="radio-item">
                    <input
                      type="radio"
                      id="heading-london"
                      name="heading"
                      value="London & Around"
                      checked={selectedHeading === 'London & Around'}
                      onChange={handleHeadingChange}
                    />
                    <label htmlFor="heading-london">London & Around</label>
                  </div>
                  <div className="radio-item">
                    <input
                      type="radio"
                      id="heading-manchester"
                      name="heading"
                      value="Manchester"
                      checked={selectedHeading === 'Manchester'}
                      onChange={handleHeadingChange}
                    />
                    <label htmlFor="heading-manchester">Manchester</label>
                  </div>
                </div>
              </div> */}
            </div>
          </Grid>
          <button onClick={toggleFilterVisibility} className="filter-toggle-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M20.5999 4.09961V6.29961C20.5999 7.09961 20.0999 8.09961 19.5999 8.59961L15.2999 12.3996C14.6999 12.8996 14.2999 13.8996 14.2999 14.6996V18.9996C14.2999 19.5996 13.8999 20.3996 13.3999 20.6996L11.9999 21.5996C10.6999 22.3996 8.89988 21.4996 8.89988 19.8996V14.5996C8.89988 13.8996 8.49989 12.9996 8.09989 12.4996L7.62989 12.0096C7.31989 11.6796 7.25989 11.1796 7.50989 10.7896L12.6299 2.56961C12.8099 2.27961 13.1299 2.09961 13.4799 2.09961H18.5999C19.6999 2.09961 20.5999 2.99961 20.5999 4.09961Z" fill="white" />
              <path d="M10.3504 3.62961L6.80039 9.31961C6.46039 9.86961 5.68039 9.94961 5.23039 9.47961L4.30039 8.49961C3.80039 7.99961 3.40039 7.09961 3.40039 6.49961V4.19961C3.40039 2.99961 4.30039 2.09961 5.40039 2.09961H9.50039C10.2804 2.09961 10.7604 2.95961 10.3504 3.62961Z" fill="white" />
            </svg>
          </button>
          <Grid xs={8.8} className='list-item-content'>
            <h4>Golf Holiday</h4>
            <p>Office ipsum you must be muted. T-shaped can air race hanging. Respectively social teams new lift
              before pee red-flag busy of. Team market live businesses masking. These cc go container
              inclusion better happenings place looking.
            </p>
            <div className='filter-tab-sec'>
              <div className='filter-tab-list'>
                <div className="tab-list-lft menutab">
                  <ul>
                    {tabs.map(tab => (
                      <li key={tab} className={tab === activeTab ? 'tab-active' : ''} onClick={() => setActiveTab(tab)}
                      >
                        {tab}
                      </li>
                    ))}
                  </ul>
                  <div className="tab-list-rgt">
                    <div className="unique-header-sort">
                      <div class="down-up-icon"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 36 36" fill="none"><path d="M24.285 3H11.715C6.255 3 3 6.255 3 11.715V24.27C3 29.745 6.255 33 11.715 33H24.27C29.73 33 32.985 29.745 32.985 24.285V11.715C33 6.255 29.745 3 24.285 3ZM16.485 25.5C16.485 25.65 16.455 25.785 16.395 25.935C16.275 26.205 16.065 26.43 15.78 26.55C15.645 26.61 15.495 26.64 15.345 26.64C15.195 26.64 15.06 26.61 14.91 26.55C14.775 26.49 14.655 26.415 14.55 26.31L10.125 21.885C9.69 21.45 9.69 20.73 10.125 20.295C10.56 19.86 11.28 19.86 11.715 20.295L14.22 22.8V10.5C14.22 9.885 14.73 9.375 15.345 9.375C15.96 9.375 16.485 9.885 16.485 10.5V25.5ZM25.86 15.72C25.635 15.945 25.35 16.05 25.065 16.05C24.78 16.05 24.495 15.945 24.27 15.72L21.765 13.215V25.5C21.765 26.115 21.255 26.625 20.64 26.625C20.025 26.625 19.515 26.115 19.515 25.5V10.5C19.515 10.35 19.545 10.215 19.605 10.065C19.725 9.795 19.935 9.57 20.22 9.45C20.49 9.33 20.805 9.33 21.075 9.45C21.21 9.51 21.33 9.585 21.435 9.69L25.86 14.115C26.295 14.565 26.295 15.285 25.86 15.72Z" fill="#292D32"></path></svg></div>
                      <div className="unique-sort-name" onClick={toggleDropdown}>
                        <li value={currentSortOrder}>{currentSortOrder}</li>
                        <div className="unique-down-icon-top">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
                              <path d="M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z" fill="#292D32" />
                            </svg>
                          </span>
                        </div>
                      </div>
                      {isDropdownOpen && (
                        <ul className="unique-dropdown-menu">
                          <li value="Sort" onClick={() => handleSortChange('Sort')}>Sort</li>
                          <li value="A to Z" onClick={() => handleSortChange('A to Z')}>A to Z</li>
                          <li value="Z to A" onClick={() => handleSortChange('Z to A')}>Z to A</li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                <div className="tab-content">
                  {activeTab === 'All' &&
                    <div className="search-result-list">
                      <div className="golf-find-total">
                        <h4>{golfHolidays ? golfHolidays.length : ''} Golf Holidays found</h4>
                        <button className={isOn ? 'list-table-sec off' : 'list-table-sec on'}>Next <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                          <path d="M8.35001 15.5666L11.025 12.8916L12.6667 11.2583C13.3583 10.5666 13.3583 9.44163 12.6667 8.74997L8.35001 4.4333C7.78334 3.86663 6.81668 4.27497 6.81668 5.06663L6.81668 9.74163L6.81668 14.9333C6.81668 15.7333 7.78334 16.1333 8.35001 15.5666Z" fill="white" />
                        </svg></span></button>
                      </div>
                      <Grid container spacing={2} className={isOn ? 'list-grid-no-mar on' : 'list-grid-no-mar off'}>
                        {golfHolidays ?
                          golfHolidays.map(holidays => (
                            <HolidayList
                              holidays={holidays}
                            />
                          ))
                          : <div style={{ display: 'flex', justifyContent: 'center' }} >
                            <Typography fontFamily={'Nunito'} fontSize={20} fontWeight={'bold'} letterSpacing={1}>
                              No Golf Holiday Found !
                            </Typography>
                          </div>}
                      </Grid>
                    </div>
                  }
                 {activeTab === 'Customizable' &&
  <div className="search-result-list">
    <div className="golf-find-total">
      <h4>{golfHolidays ? filterCustomizableHolidaysByPriceType(golfHolidays, 'customizable').length : ''} Golf Holidays found</h4>
      <button className={isOn ? 'list-table-sec off' : 'list-table-sec on'}>Next <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
        <path d="M8.35001 15.5666L11.025 12.8916L12.6667 11.2583C13.3583 10.5666 13.3583 9.44163 12.6667 8.74997L8.35001 4.4333C7.78334 3.86663 6.81668 4.27497 6.81668 5.06663L6.81668 9.74163L6.81668 14.9333C6.81668 15.7333 7.78334 16.1333 8.35001 15.5666Z" fill="white" />
      </svg></span></button>
    </div>
    <Grid container spacing={2} className={isOn ? 'list-grid-no-mar on' : 'list-grid-no-mar off'}>
      {golfHolidays ?
        filterCustomizableHolidaysByPriceType(golfHolidays, 'customizable').map(holidays => (
          <HolidayList
            holidays={holidays}
          />
        ))
        : <div style={{ display: 'flex', justifyContent: 'center' }} >
          <Typography fontFamily={'Nunito'} fontSize={20} fontWeight={'bold'} letterSpacing={1}>
            No Golf Holiday Found !
          </Typography>
        </div>}
    </Grid>
  </div>
}
                 {activeTab === 'Non-Customizable' &&
  <div className="search-result-list">
    <div className="golf-find-total">
      <h4>{golfHolidays ? filterCustomizableHolidaysByPriceType(golfHolidays, 'non-customizable').length : ''} Golf Holidays found</h4>
      <button className={isOn ? 'list-table-sec off' : 'list-table-sec on'}>Next <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
        <path d="M8.35001 15.5666L11.025 12.8916L12.6667 11.2583C13.3583 10.5666 13.3583 9.44163 12.6667 8.74997L8.35001 4.4333C7.78334 3.86663 6.81668 4.27497 6.81668 5.06663L6.81668 9.74163L6.81668 14.9333C6.81668 15.7333 7.78334 16.1333 8.35001 15.5666Z" fill="white" />
      </svg></span></button>
    </div>
    <Grid container spacing={2} className={isOn ? 'list-grid-no-mar on' : 'list-grid-no-mar off'}>
      {golfHolidays ?
        filterCustomizableHolidaysByPriceType(golfHolidays, 'non-customizable').map(holidays => (
          <HolidayList
            holidays={holidays}
          />
        ))
        : <div style={{ display: 'flex', justifyContent: 'center' }} >
          <Typography fontFamily={'Nunito'} fontSize={20} fontWeight={'bold'} letterSpacing={1}>
            No Golf Holiday Found !
          </Typography>
        </div>}
    </Grid>
  </div>
}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        {isFilterVisible && window.innerWidth < 768 && <div className="overlay"></div>}
      </div>
    </div >
  )
}

export default GolfHolidays;
