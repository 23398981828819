import React, { useState, useEffect } from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import GolfExperiences from './GolfExperiences';

const GolfExperiencesList = () => {
 const [details, setDetails] = useState({'title':'Golf Experiance','meta_desc':'Golf Experiance','meta_keyword':'experiance , golftripz , golf'})
    
    return (
        <Layout head={"Golf Experiences"} details={details}>
            <GolfExperiences/>
        </Layout>
    )
}

export default GolfExperiencesList;
