import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { Typography } from '@mui/material';
import footlogo from '../assets/images/footer-logo.png'
import twitlogo from '../assets/images/footer-twi.png'
import car from '../assets/images/car.gif'
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider'
import { useLocation, useNavigate ,Redirect } from 'react-router-dom'
import { SET_PREVIOUS_ROUTE } from '../Redux/constants/userConstants'
import { Stack } from '@mui/system';
import { Avatar, Grid } from '@mui/material'
import RegisterMenu from './RegisterMenu';
import Link from '@mui/material/Link';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/Constants'
import { toast } from 'react-toastify';
const Footer = () => {

  // const { user } = useSelector(state => state.auth)
  // const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  // let history = useHistory();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [email, setEmail] = React.useState("");
  const [validation, setValidation] = React.useState(false);
  const {footerMenus } =useSelector(state => state.booking)
  const [menus, setMenus] = useState(footerMenus);
  
  
  useEffect(() => {
    
     if( footerMenus !==undefined){
      setMenus(footerMenus)
     }
   }, [footerMenus])

   
  function  NavigationPath(slug){
    navigate('/'+slug)
    // var url ="/"+slug;
    // history.push(url);
  }

 
  const Contactus = () => {
    setAnchorElNav(null);
    navigate('/contactus')
  }; 
 
  const PaymentRefund = () => {
    setAnchorElNav(null);
    navigate('/payment-cancellations')
  };
 
  const PrivacyPolicy = () => {
    setAnchorElNav(null);
    navigate('/privacy-policy')
  };
 
  const CustomerRegister = () => {
    setAnchorElNav(null);
    navigate('/customerregistration')
  };

  const CompanyRegister = () => {
    setAnchorElNav(null);
    navigate('/b2bregistration')
  };

  function  Subscribe(){
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
        setValidation(true);
    }else{
        setValidation(false);
        axios(API_URL+'/subscribe-newsletter',{
        method: 'POST',
        data: {email:email},
        headers: {
       'Content-Type': 'application/json'
     }
    })
      .then(function(response) {
        toast.success(response.data.message)
        }).then(function(body) {
          console.log(body);
        });
    }
  }
  return (
    <footer id="footer" className='footer'>
      <div className='full-con-width'>
        <div className="foot-logo">
          <img src={footlogo} alt="logo" />
        </div>
        <Grid container spacing={2} className='footer-details-con'>
          <Grid item md={6} xs={12} className='dis-foot-details'>
            <Grid item md={6} xs={6} className='footer-details-subcon'>
              <h4>Golf Tripz</h4>
              <ul className="footer-links">

                {menus ?
                  menus.map(item => (
                    <li style={{ "cursor": 'pointer' }}><span onClick={() => NavigationPath(item.slug)}>{item.name}</span></li>
                  ))
                  : ""}
              </ul>
              {/* <ul className="footer-links">  
              <li style={{ "cursor": 'pointer' }}><span onClick={Contactus} >Contact Us</span></li>
              <li style={{ "cursor": 'pointer' }}><span onClick={Testimonials} >Testimonials</span></li>
              <li style={{ "cursor": 'pointer' }}><span onClick={Deals} >Deals & Discounts</span></li>
              <li style={{ "cursor": 'pointer' }}><span onClick={GiftHolidays} >Gift a Holiday</span></li>
              <li style={{ "cursor": 'pointer' }}> <span onClick={Brochures} >Brochures </span></li>
              <li style={{ "cursor": 'pointer' }}><span onClick={News} >News</span></li>
              <li style={{ "cursor": 'pointer' }}><span onClick={EventTrips} >Upcoming Golf Events & Trips</span></li>
              <li style={{ "cursor": 'pointer' }}>Covid-19 Travel Updates</li>
              <li style={{ "cursor": 'pointer' }}>OCBC QR code</li>
              <li style={{ "cursor": 'pointer' }}>Quick Payments Razorpay</li>
              <li style={{ "cursor": 'pointer' }}>HDFC Bank & Golftripz Offer</li>
            </ul> */}
            </Grid>
            {/* <Grid item md={3} xs={12}>
            <h4>&nbsp;</h4>
            <ul className="footer-links">
              <li style={{ "cursor": 'pointer' }}><span onClick={Stableford}>World Team Stableford Challenge</span></li>
              <li style={{ "cursor": 'pointer' }}><span onClick={PrivacyPolicy}>Privacy Policy</span></li>
              <li style={{ "cursor": 'pointer' }}><span onClick={GolfPackages}>Stay & Play Golf Packages</span></li>
              <li style={{ "cursor": 'pointer' }}><span onClick={PaymentRefund}>Payments Cancellations & Refunds</span></li>
              <li style={{ "cursor": 'pointer' }}>Booking Process, Terms & Conditions</li>
              <li style={{ "cursor": 'pointer' }}> <span onClick={AboutUs} >About Us</span></li>
              <li style={{ "cursor": 'pointer' }}>Golftripz Referral Program</li>
            </ul>
          </Grid> */}
            <Grid item md={6} xs={6} className='footer-details-subcon'>
              <h4>Useful Links</h4>
              <ul className="footer-links">
                <li style={{ "cursor": 'pointer' }}><span>Golf Holidays</span></li>
                <li style={{ "cursor": 'pointer' }}><span>Golf Courses</span></li>
                <li style={{ "cursor": 'pointer' }}>Golf Destination</li>
                <li style={{ "cursor": 'pointer' }}>Golf Lessons</li>
                <li style={{ "cursor": 'pointer' }}>Hotel</li>
                <li style={{ "cursor": 'pointer' }}>Resorts</li>
                <li style={{ "cursor": 'pointer' }}>Sightseeing</li>
                <li style={{ "cursor": 'pointer' }}>Transportation</li>
              </ul>
            </Grid>
          </Grid>
          <Grid item md={2} xs={12} className='footer-details-subcon'>
            <h4>Follow Us </h4>
                    <div className="footer-social-links">
                        <div className="fb">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 34 34" fill="none">
                                <path d="M16.9143 33.9166C26.2559 33.9166 33.8287 26.3438 33.8287 17.0022C33.8287 7.6607 26.2559 0.0878906 16.9143 0.0878906C7.57281 0.0878906 0 7.6607 0 17.0022C0 26.3438 7.57281 33.9166 16.9143 33.9166Z" fill="#3C5A99"/>
                                <path d="M18.2293 25.4594V17.7554H20.8259L21.2158 14.7393H18.2293V12.8199C18.2293 11.9477 18.4705 11.3564 19.7192 11.3564H21.3017V8.66727C21.0275 8.63093 20.0826 8.54834 18.9825 8.54834C16.6866 8.54834 15.114 9.94906 15.114 12.5226V14.7426H12.5273V17.7587H15.114V25.4594H18.2293Z" fill="white"/>
                            </svg>
                        </div>
                        <div className="pin">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 34" fill="none">
                                <path d="M34.6568 17.0022C34.6568 7.6607 27.084 0.0878906 17.7425 0.0878906C8.40094 0.0878906 0.828125 7.6607 0.828125 17.0022C0.828125 26.3438 8.40094 33.9166 17.7425 33.9166C27.084 33.9166 34.6568 26.3438 34.6568 17.0022Z" fill="#FF0000"/>
                                <path d="M16.915 8.63767C14.1694 8.95175 11.4333 11.2278 11.3204 14.479C11.2494 16.4642 11.7995 17.9534 13.6434 18.3716C14.4436 16.9253 13.3853 16.6063 13.2208 15.56C12.5448 11.2724 18.0475 8.34842 20.9271 11.3418C22.9194 13.4146 21.6079 19.7914 18.3943 19.1286C15.3163 18.4956 19.9011 13.4195 17.4442 12.4228C15.447 11.6129 14.3855 14.9005 15.3325 16.5336C14.7775 19.3419 13.5821 21.9881 14.0661 25.5105C15.6357 24.3435 16.1649 22.1088 16.5988 19.7782C17.3877 20.2691 17.8087 20.7799 18.8154 20.8592C22.5274 21.1534 24.6004 17.0625 24.0938 13.289C23.6437 9.94346 20.385 8.24098 16.915 8.63767Z" fill="white"/>
                            </svg>
                        </div>
                        <div className="twit">
                           <img src={twitlogo} alt="logo"/>
                        </div>
                        <div className="linked">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 34" fill="none">
                                <path d="M17.5765 33.9166C26.918 33.9166 34.4908 26.3438 34.4908 17.0022C34.4908 7.6607 26.918 0.0878906 17.5765 0.0878906C8.23492 0.0878906 0.662109 7.6607 0.662109 17.0022C0.662109 26.3438 8.23492 33.9166 17.5765 33.9166Z" fill="#007AB9"/>
                                <path d="M27.6833 18.3635V25.3369H23.6403V18.8309C23.6403 17.1973 23.0566 16.0817 21.5927 16.0817C20.4756 16.0817 19.812 16.8327 19.5189 17.56C19.4125 17.8199 19.3851 18.1808 19.3851 18.5453V25.3366H15.3418C15.3418 25.3366 15.396 14.3175 15.3418 13.1769H19.3854V14.9C19.3772 14.9136 19.3658 14.9269 19.3585 14.9398H19.3854V14.9C19.9227 14.0733 20.8809 12.8913 23.0292 12.8913C25.6891 12.8913 27.6833 14.6293 27.6833 18.3635ZM11.1121 7.31543C9.72906 7.31543 8.82422 8.22329 8.82422 9.41608C8.82422 10.5835 9.70283 11.5176 11.059 11.5176H11.0853C12.4955 11.5176 13.3723 10.5835 13.3723 9.41608C13.3454 8.22329 12.4955 7.31543 11.1121 7.31543ZM9.06453 25.3369H13.1063V13.1769H9.06453V25.3369Z" fill="#F1F2F2"/>
                            </svg>
                        </div>
                        <div className="yout">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 34" fill="none">
                                <path d="M34.3189 17.0022C34.3189 7.6607 26.7461 0.0878906 17.4046 0.0878906C8.06305 0.0878906 0.490234 7.6607 0.490234 17.0022C0.490234 26.3438 8.06305 33.9166 17.4046 33.9166C26.7461 33.9166 34.3189 26.3438 34.3189 17.0022Z" fill="#FF0000"/>
                                <path d="M26.8141 12.3496C26.5932 11.5192 25.9399 10.8642 25.1086 10.6397C23.6055 10.2368 17.5748 10.2368 17.5748 10.2368C17.5748 10.2368 11.5471 10.2368 10.041 10.6397C9.21275 10.8611 8.55939 11.5162 8.33546 12.3496C7.93359 13.8565 7.93359 17.0025 7.93359 17.0025C7.93359 17.0025 7.93359 20.1486 8.33546 21.6555C8.55632 22.4858 9.20968 23.1409 10.041 23.3654C11.5471 23.7683 17.5748 23.7683 17.5748 23.7683C17.5748 23.7683 23.6055 23.7683 25.1086 23.3654C25.9368 23.144 26.5902 22.4889 26.8141 21.6555C27.216 20.1486 27.216 17.0025 27.216 17.0025C27.216 17.0025 27.216 13.8565 26.8141 12.3496Z" fill="white"/>
                                <path d="M15.6484 19.9021L20.6577 17.0021L15.6484 14.1021V19.9021Z" fill="#FF0000"/>
                            </svg>
                        </div>
                    </div>
          </Grid>
          <Grid item md={2} xs={12} className='footer-details-subcon'>
            <h4 className="mail-subscribe-sec">Subscribe to Our Newsletter & Receive Latest Updates </h4>
            <div className="foot-email-enter">
              <input type="email" name="" id="enter-email" onChange={(event) => setEmail(event.target.value)} placeholder="Your email address" />
              {validation == true?
              <span className="emailvalidate" style={{color:"red"}}>Please enter valid Email</span>
              :""}
                 <button  onClick={() => Subscribe()}>Subscribe</button>
            </div>
          </Grid>
        </Grid>

      </div>
      <div className="footer-line-tree">
            <div className="wh-line">
                <svg xmlns="http://www.w3.org/2000/svg" width="1450" height="57" viewBox="0 0 1823 57" fill="none">
                    <path d="M0 55.5444H1520C1534.33 55.8777 1569.9 51.9444 1597.5 33.5444C1632 10.5444 1681 -26.4556 1768.5 33.5444C1777 40.8777 1799.8 55.5444 1823 55.5444" stroke="white" stroke-width="2"/>
                </svg>
            </div>
            <div className="green-tree">
                <svg width="307" height="137" viewBox="0 0 429 193" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M239.808 87.8784L253.186 35L274.157 84.1259L268.011 82.42L283.196 111.759L274.519 109.03L288 139L238.589 125.509L241.977 110.395L238 115.512L246.694 84.5617L239.808 87.8784Z" fill="#004F11"/>
                    <path d="M190.315 67.6781L207.447 0L234.303 62.8751L226.432 60.692L245.879 98.2425L234.766 94.7494L247 127C227.693 121.792 207.942 118.374 188 116.788L193.093 96.4958L188 103.045L199.134 63.4328L190.315 67.6781Z" fill="#019321"/>
                    <path d="M158.807 80.2083L172.184 27L193.153 76.4322L187.007 74.7158L202.192 104.238L193.515 101.492L204 120C188.438 117.682 172.643 117.366 157 119.061L160.977 102.865L157 108.014L165.694 76.8707L158.807 80.2083Z" fill="#025E16"/>
                    <path d="M109.28 78.564L126.152 11L152.6 73.7693L144.848 71.5897L164 109.077L153.056 105.59L162.547 117.078C139.682 120.099 125.199 122.423 107 130L112.016 107.333L107 113.872L117.965 74.326L109.28 78.564Z" fill="#019321"/>
                    <path d="M18.006 186.674C46.3806 172.506 75.3727 147.343 105.444 129.612C136.888 111.071 174.815 101.708 211.05 106.124C235.102 109.055 257.481 119.732 278.742 131.354C300.004 142.975 320.841 155.778 343.776 163.591C366.712 171.405 385.224 172.684 415.925 166.163L429 157C429 157 397.5 181 371.5 186.5C345 195 0 191.542 0 191.542L18.006 186.674Z" fill="#007419"/>
                    <g opacity="0.1">
                    <path d="M84.9268 141.518C84.9449 141.68 85.7541 141.774 87.2659 141.805C88.775 141.802 90.9974 141.876 93.8386 142.002C101.852 142.502 109.793 143.827 117.535 145.955C127.382 148.533 138.778 152.802 151.517 157.59C157.893 159.966 164.633 162.435 171.785 164.63C179.191 166.98 186.783 168.695 194.479 169.759C198.426 170.256 202.4 170.509 206.378 170.516L209.317 170.426L210.778 170.375L212.231 170.248C213.197 170.16 214.159 170.073 215.118 169.987L217.964 169.593C225.125 168.361 232.146 166.424 238.925 163.81C245.376 161.418 251.316 158.826 256.882 156.505C262.441 154.171 267.624 152.109 272.444 150.495C279.991 147.807 287.857 146.12 295.843 145.477C301.505 145.134 304.683 145.476 304.734 145.093C304.782 144.835 301.666 143.863 295.811 143.641C289.978 143.439 281.432 144.326 271.444 147.312C266.442 148.759 261.078 150.684 255.422 152.874C249.763 155.051 243.793 157.495 237.449 159.72C230.903 162.117 224.145 163.893 217.266 165.023L214.588 165.371L211.873 165.596L210.508 165.709L209.131 165.748L206.362 165.821C202.589 165.796 198.82 165.548 195.077 165.077C187.639 164.046 180.297 162.416 173.122 160.204C166.106 158.117 159.404 155.762 152.999 153.502C146.594 151.241 140.483 149.078 134.685 147.229C128.89 145.375 123.408 143.838 118.328 142.714C113.755 141.683 109.123 140.938 104.458 140.481L101.472 140.235L98.6986 140.137L97.3995 140.095L96.1561 140.115L93.8518 140.165C91.6189 140.244 89.3941 140.475 87.1927 140.857C85.6846 141.133 84.909 141.356 84.9268 141.518Z" fill="black"/>
                    </g>
                    <g opacity="0.1">
                    <path d="M62.9462 159.847C63.004 160.075 66.6753 158.984 73.4805 158.202C80.27 157.368 90.2583 157.335 102.343 159.323C108.933 160.428 115.462 161.873 121.903 163.654C128.87 165.518 136.246 167.821 144.049 170.155C151.853 172.484 160.092 174.879 168.817 176.717C177.832 178.717 187.041 179.708 196.275 179.671C200.957 179.578 205.631 179.236 210.277 178.647C214.853 178.069 219.32 177.256 223.682 176.367C232.402 174.553 240.673 172.309 248.528 170.168C256.384 168.028 263.821 165.951 270.851 164.343C277.353 162.804 283.93 161.596 290.555 160.724C300.136 159.391 309.854 159.356 319.445 160.619C321.147 160.821 322.638 161.12 323.934 161.367L325.731 161.702L327.204 162.05C328.091 162.321 329.005 162.491 329.93 162.557C329.102 162.137 328.229 161.813 327.327 161.591L325.872 161.13L324.086 160.682C322.794 160.361 321.301 159.987 319.592 159.713C309.931 158.079 300.084 157.861 290.361 159.066C283.659 159.833 277.003 160.957 270.421 162.434C263.316 163.972 255.828 165.986 247.963 168.069C240.097 170.151 231.853 172.338 223.217 174.103C218.898 174.966 214.486 175.758 209.982 176.318C205.423 176.887 200.837 177.219 196.243 177.311C187.181 177.345 178.143 176.387 169.289 174.453C160.67 172.67 152.47 170.337 144.665 168.07C136.857 165.796 129.442 163.557 122.401 161.761C115.879 160.039 109.268 158.675 102.598 157.674C90.3386 155.874 80.1913 156.165 73.3617 157.291L70.9421 157.674L68.8397 158.129L67.0422 158.529C66.4972 158.665 66.0108 158.82 65.5732 158.945C64.666 159.145 63.7847 159.447 62.9462 159.847Z" fill="black"/>
                    </g>
                    <g opacity="0.1">
                    <path d="M234.153 140.601C233.453 140.814 232.866 141.296 232.519 141.94C232.173 142.584 232.095 143.339 232.302 144.04C232.51 144.742 232.986 145.333 233.627 145.685C234.269 146.036 235.023 146.121 235.726 145.919C236.429 145.717 237.024 145.246 237.381 144.608C237.738 143.969 237.829 143.216 237.633 142.511C237.437 141.806 236.971 141.207 236.336 140.845C235.7 140.482 234.947 140.386 234.241 140.576" fill="white"/>
                    </g>
                    <path d="M223.822 111.858C223.941 111.936 225.298 109.93 227.345 107.782C229.379 105.622 231.308 104.159 231.224 104.045C228.106 105.945 225.551 108.642 223.822 111.858Z" fill="#263238"/>
                    <path d="M224.624 99.5342C224.478 99.5269 224.393 101.937 223.552 104.752C222.734 107.575 221.5 109.646 221.626 109.719C223.768 106.77 224.827 103.173 224.624 99.5342Z" fill="#263238"/>
                    <path d="M217.227 95C217.127 95.7234 217.094 96.4545 217.128 97.184C217.106 98.5292 217.054 100.385 216.904 102.43C216.753 104.475 216.534 106.318 216.359 107.653C216.218 108.369 216.144 109.097 216.138 109.827C216.409 109.146 216.594 108.433 216.689 107.706C217.345 104.238 217.605 100.706 217.462 97.1795C217.474 96.4461 217.396 95.7139 217.227 95Z" fill="#263238"/>
                    <path d="M105.394 176.415C107.3 173.986 108.954 171.368 110.328 168.603C108.422 171.032 106.768 173.65 105.394 176.415Z" fill="#263238"/>
                    <path d="M99.6553 175.621C100.214 175.453 100.735 175.18 101.19 174.816C103.514 173.206 105.261 170.894 106.175 168.219C106.401 167.681 106.522 167.105 106.531 166.522C105.289 170.222 102.875 173.416 99.6553 175.621Z" fill="#263238"/>
                    <path d="M88.9454 178.045C89.6676 177.965 90.372 177.768 91.031 177.462C94.3074 176.152 97.1085 173.878 99.0632 170.941C99.4983 170.359 99.8358 169.71 100.062 169.02C97.4453 173.164 93.5395 176.335 88.9454 178.045Z" fill="#263238"/>
                    <path d="M241.089 136.119C241.192 136.022 239.625 134.294 238.045 131.912C236.453 129.536 235.461 127.426 235.332 127.484C236.475 130.81 238.458 133.784 241.089 136.119Z" fill="#263238"/>
                    <path d="M245.625 133.228C243.647 131.077 241.443 129.145 239.05 127.467C241.028 129.618 243.232 131.55 245.625 133.228Z" fill="#263238"/>
                    <path d="M255.07 134.063C254.437 133.686 253.775 133.362 253.089 133.094C249.883 131.613 246.861 129.761 244.086 127.576C243.535 127.087 242.946 126.643 242.323 126.25C242.767 126.844 243.288 127.377 243.873 127.833C246.565 130.188 249.636 132.071 252.957 133.4C253.629 133.714 254.34 133.937 255.07 134.063Z" fill="#263238"/>
                </svg>
                <div className="flag-footer">
                    <svg width="30" height="109" viewBox="0 0 30 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="post" d="M26.1368 1.92019C26.1352 1.56786 26.231 1.22193 26.4136 0.920624C26.5962 0.619316 26.8586 0.374355 27.1717 0.212792C27.4848 0.051229 27.8364 -0.0206416 28.1878 0.0051147C28.5392 0.030871 28.8767 0.15325 29.1629 0.358741C29.4491 0.564233 29.6729 0.844838 29.8096 1.16956C29.9464 1.49427 29.9907 1.85046 29.9377 2.19879C29.8847 2.54712 29.7365 2.87402 29.5094 3.14339C29.2823 3.41276 28.9851 3.6141 28.6508 3.72519V108.47H27.4455V3.72519C27.0654 3.59991 26.7345 3.35813 26.4995 3.03413C26.2646 2.71013 26.1377 2.32039 26.1368 1.92019Z" fill="#E0E0E0"/>
                        <path className="flag" d="M7.0552 17.8175C9.78399 18.2379 11.7845 15.946 12.4223 13.9051C13.0111 12.0214 13.2912 10.0614 14.1183 8.26952C15.2746 5.76522 18.3372 4.60807 21.0857 4.8395C23.7743 5.09931 26.3791 5.91759 28.7332 7.24197V23.066C28.7332 23.066 23.0694 17.1854 20.5247 17.0827C16.2789 16.9113 16.51 23.8902 12.3873 24.9206C10.6087 25.3233 8.7521 25.2098 7.03589 24.5932C5.31968 23.9766 3.81525 22.8828 2.6996 21.4403C1.1727 19.3641 0.239501 16.9115 0 14.3455C0 14.3455 1.89974 17.0234 7.0552 17.8175Z" fill="#FF0000"/>
                    </svg>
                </div>
            </div>
            <div className="car">
               <img src={car} alt="" />
            </div>
        </div>
        <div className="copy-right">
            <p>© 2024 GOLFTRIPZ</p>
        </div>
    </footer>
  )

}

export default Footer
