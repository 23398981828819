import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import GolfHolidaysList from './GolfHolidaysList';
import {getGolfResults } from '../../Redux/actions/bookingActions'
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants'
import { Dataset } from '@mui/icons-material';

const SearchResult = () => {

    const navigate = useNavigate()
    const [details, setDetails] = useState({'title':'Golfcourses -Golftripz','meta_desc':'Golfcourses list','meta_keyword':'services , golftripz , golfcourses'})
    const [datas, setDatas] = useState(localStorage.getItem("booking"))
    const dispatch = useDispatch();
    const { availableCourses }  = useSelector(state => state.booking)
    const [golfCourses, setGolfCourses] = useState(availableCourses);
    useEffect(() => {
        localStorage.removeItem("booking");
        getGolfCourseData()
      }, []);
      const getGolfCourseData = async() => {
     
        var datas = localStorage.getItem("booking");	
        var search = localStorage.getItem("search");	
      
        if(search ===null){
           
            var data = {
                'search':"",
                'startdate':new Date()
            }
            localStorage.setItem("booking", JSON.stringify(data))
		
            let bookinData = JSON.parse(localStorage.getItem("search"));
            dispatch({
                type: BOOKING_DATA,
                payload: bookinData
            })
            await dispatch(getGolfResults(data));
        }else{
          
            let bookinData = JSON.parse(localStorage.getItem("search"));
            let search = JSON.parse(localStorage.getItem("booking"));
            
            dispatch({
                type: BOOKING_DATA,
                payload: bookinData
            })
            await  dispatch(getGolfResults(bookinData));
        }
       
    }
    return (
        <Layout head={"SearchResult"} details={details}>
            <GolfHolidaysList/>   
        </Layout>
    )
}

export default SearchResult
