import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Button } from '@mui/material'
import profile from '../../assets/images/profile.png'
import Slider from "react-slick";
import moment from 'moment';
const Reviews = ({ courses }) => {

    
 // --------------------slider------------------------------
 var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
// ---------------------slider-------------------------------
const [reviewlist ,setReview] = useState([])
useEffect(() => {
   
  if(courses?.golf_courses?.reviews.length ===1){
      const reviews = [];
      reviews.push(courses?.golf_courses?.reviews);

      const reviewsduplicate = courses?.golf_courses?.reviews;

      reviews.push(reviewsduplicate); 
      setReview(reviews)
  }else{
    setReview(courses?.golf_courses?.reviews)
  }
}, [])
    return(
        <div className="slider-container detailsinfo-con">
        <h4 className="details-cont-tabhead">Reviews</h4>
       
        {reviewlist?.length >0 ?
             <Slider {...settings} className='test-slick-slide'>
           {reviewlist.slice(0, 5).map((review,i) => (
            <div className='review-ddetails-bx' key={i}>
                <div className='test-head-prof'>
                    <div className='test-prof-dd'>
                        <div className='test-prof'>
                            <img src={profile} alt="profile" />
                        </div>
                        <div className='test-prof-name'>
                            <p>{review.name}</p>
                        </div>
                    </div>
                    <p>{moment(review?.date).format("MMM Do YYYY")}</p>
                </div>
                <div className='test-userreview'>
                    <p>{review.message}</p>
                </div>
                <div className='test-date-ex'>
                    {/* <h5>Date of experience : <span>{moment(review?.date).format("MMM Do YYYY")}</span></h5> */}
                </div>
            </div>
             ))}
             </Slider>
             :"No Reviews Yet!"
            }
       
    </div>
    )
}
export default Reviews;