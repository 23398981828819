import React, { useEffect, useState} from 'react'
import './login.scss'
import { Grid, Typography, Button, Box, Container } from '@mui/material'
import InputField from '../../components/InputField'
import { Link, useNavigate, useNavigation } from "react-router-dom";
import GoogleImg from '../../assets/images/google.png'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import { googleSignIn, loginUsers } from '../../Redux/actions/userActions'
import { toast } from 'react-toastify'
import { RESET_ERROR } from '../../Redux/constants/userConstants'
import { useGoogleLogin } from '@react-oauth/google';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeTopBar from '../../components/HomeTopBar';
import Footer from '../../components/Footer';
import Banner from '../../components/Banner';
import BorderBox from '../../components/BorderBox';
import axios from 'axios'
import { display } from '@mui/system';
import Avatar from '@mui/material/Avatar/Avatar';
import logo from '../../assets/images/logo.png'
import login_b from '../../assets/images/login_b.jpg'
import Layout from '../Layout';


const Login = () => {

	const dispatch = useDispatch();
	const {courseBookingInputs,coureBookingSuccess,selectedCourse,holidaybookingnotlogin } =useSelector(state => state.booking)
	const [emailphone, setEmailPhone] = useState("")
	const [passwordnew, setPassword] = useState("")

	const login = useGoogleLogin({
		onSuccess: tokenResponse => {
			getUserProfile(tokenResponse.access_token)
		},
	});

console.log(holidaybookingnotlogin)
	const getUserProfile = async (token) => {
		await axios
			.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: 'application/json'
				}
			})
			.then((res) => {
				//setProfile(res.data);


				let data = {
					email: res?.data?.email,
					name: res?.data?.name
				}
				localStorage.setItem("loginType", "google");
				dispatch(googleSignIn(data))

			})
			.catch((err) => toast.error({ err }));
	}

	const { loading, isAuthenticated, error } = useSelector(state => state.auth)

	const schema = yup.object({
		
		emailorphone: yup.string().required('Email or phone required').max(30, 'Maximum character exceeded'),
		password: yup.string()
			.required('No password provided.')
			.min(8, 'Password should contain at least 8 characters').max(30, "Maximum character exceeded")
	}).required();

	const navigate = useNavigate();
	const { register, handleSubmit, control, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		mode: 'onChange'
	});

	useEffect(() => {
		if (error) {
			toast.error(error)
			dispatch({
				type: RESET_ERROR
			})
		}
		
		if (isAuthenticated && holidaybookingnotlogin !== undefined) {
			navigate('/GolfHolidayDetailsPage')
		}
		if (isAuthenticated && courseBookingInputs === undefined && holidaybookingnotlogin === undefined) {
			navigate("/myacc")
		}
		if (isAuthenticated && courseBookingInputs !== undefined) {
			navigate('/PassangerDetailsPage')
		}
	}, [error, isAuthenticated])


	const onSubmit = data => {
		localStorage.setItem("loginType", "form");
		dispatch(loginUsers(data))
		
		if (isAuthenticated && courseBookingInputs !== undefined) {
			navigate('/PassangerDetailsPage')
		}
	};



	return (
		<div style={{ position: 'relative' }}> 
			<Grid className='log-bg-con' container justifyContent="center" paddingTop={"40px"} paddingBottom={"40px"} >
				<Grid className='log-bg' xs={12} md={6}>
					
					<img src={login_b} alt="" />
				</Grid>

				<Grid xs={12} md={6} className="log-frm-holder" position={'relative'} >
				<div className='head_section'>
                            {/* <Grid className='log-ico'>
                            <img src={logo} alt="" />
                        </Grid> */}
                        <div className='backtohome-login' onClick={() => navigate('/')} style={{  display: 'flex', alignItems: 'center', cursor: 'pointer', padding: 10 }}><ArrowBackIcon style={{ fontSize: 30 }} /> Back to Home</div>
                            
                            </div>
					<div className='log-frm-con'>
					<Grid className='log-ico'>
                            <img src={logo} alt="" />
                        </Grid>
					<h1 className="login_title">Login</h1>
					<Typography className="login_subtitle">Be a part of <span className='color-h'>GolfTripz</span> Today</Typography>
						{/* <Grid position={'absolute'} width={'100%'} height={50} boxShadow={.5} justifyContent={'center'} top={0} display={{ md: 'none', xs: 'flex' }} alignItems={'center'} px={2}>
							<Avatar src={logo} variant="square" sx={{ width: 60, height: 30, cursor: 'pointer' }} onClick={() => navigate('/')}>

							</Avatar>
						</Grid> */}
						<form onSubmit={handleSubmit(onSubmit)}>

							<Grid className='' spacing={2} container>
								<Grid className='log-input-frm' item xs={12} md={12} style={{}}>
									<div style={{}}>

										<Box width={{ xs: "100%", md: '100%' }}>
											<InputField style={{ borderRadius: '0px' }}
												fieldLabel="Email Address / Mobile No"
												fieldName="emailorphone"
												placeholder="Enter Email Address / Mobile No"
												control={control}
												error={errors.emailorphone}
												width={{ xs: "100%", md: '50%' }}
												fieldType="text"
												fieldChange={(e) => {
													setEmailPhone(e.target.value)
												}}
											/>
											<div style={{ paddingTop: 5, marginTop: '10px' }}>
												<InputField
													fieldLabel="Password"
													fieldName="password"
													placeholder="Enter Password"
													control={control}
													error={errors.password}
													width={{ xs: "100%", md: '50%' }}
													fieldType={"password"}
													fieldChange={(e) => {
														setPassword(e.target.value)
													}}
												/>
											</div>
										</Box>

									</div>
									<Box width={{ xs: "100%", md: '100%' }} display={"flex"} justifyContent="flex-end" paddingTop={"1px"} paddingBottom={1}>
										<Typography onClick={() => navigate("/forgotpassword")} style={{ fontFamily: 'Nunito', cursor: 'pointer', fontSize: 12, marginRight: 10, }}>FORGOT PASSWORD?</Typography>
									</Box>

								</Grid>
								<Grid item xs={12} md={12} lg={12} className=''>
									<Button type="submit" fullWidth="true" className="btn_login log-btn-c"
										sx={{ boxShadow: 2 }}
										variant="contained" style={{}}
									>
										<Typography className='buttonText'>Login</Typography>
									</Button>
								</Grid>
								<Grid item xs={12} md={12} lg={12} className=''>
									{/* <Button variant="contained" onClick={() => login()} className="btn_google_login" style={{}}
										startIcon={<img src={GoogleImg} style={{ width: 22, height: 22, margin: 0, paddingLeft: 2 }} />}>
										<Typography className='buttonText'> Google Sign In</Typography>
									</Button> */}
									
								</Grid>
								<Grid item xs={12} md={12} lg={12} className=''>
									<span style={{ fontFamily: 'Nunito', fontSize: 13, marginRight: 10 }}>Don't have an account yet ? <Link to={"/customerregistration"} className='accountText' style={{ cursor: 'pointer', fontFamily: 'Nunito' }}>Sign Up</Link></span>
								</Grid>
							</Grid>

						</form>
					</div>
				</Grid>
			</Grid>
			{/* <Footer/> */}

		</div>
	)
}

export default Login
