import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useForm } from "react-hook-form";
import InputField from '../../components/InputField'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import moment from 'moment';
import { toast } from 'react-toastify';
import { Grid, Button, Card } from '@mui/material';
import { useNavigate } from "react-router-dom";
import './booking.scss'
import DatePicker from 'react-datepicker';
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { useDispatch, useSelector } from 'react-redux';
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants';
const StyledTabs = styled((props) => (
	<Tabs
		{...props}
		variant='scrollable'
		TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}

	/>
))({
	borderRadius: '0px',
	height: '10px',
	justifyContent: 'center',
	alignItems: 'center',
	'& .MuiTabs-indicator': {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
	'& .MuiTabs-indicatorSpan': {
		width: '100%',
		backgroundColor: '#EDBA37',
	},
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: 'none',
		fontFamily: 'nunito',
		fontWeight: '700',
		borderRadius: 0,
		fontSize: theme.typography.pxToRem(12),
		marginRight: theme.spacing(0),
		color: '#333333',
		boxShadow: 0,
		height: "30px !important",
		margin: 0,
		//width: '33.3%',
		//padding: '2px',
		backgroundColor: '#F6F6F6',
		'&.Mui-selected': {
			color: '#fff',
			backgroundColor: '#EDBA37'
		},

	}),
);
function TabPanel(props) {
	const { children, value, index, ...other } = props;
	
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 2 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function BookingDetails() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const { bookingData } = useSelector(state => state.booking)
	const [startDate, setStartDate] = useState(new Date());
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [search, setSearch] = React.useState(null);
	const [alignment, setAlignment] = React.useState('oneway');
	useEffect(() => {
		if (bookingData) {
			
		}
	}, [bookingData])




	//const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY })


	const handleChange = (event, newValue) => {
		dispatch({
            type: BOOKING_DATA,
            payload:null
        })
		// setValue(newValue);
	};
	const handleSubmit =(e)=>{
		
		var data = {
			'search':search,
			'startdate':startDate
		}
		
		e.preventDefault();
		localStorage.setItem("booking", JSON.stringify(data))
		localStorage.setItem("search", JSON.stringify(data))
		navigate('/SearchResult');
	}
	
	return (
		<form onSubmit={handleSubmit}>
			<div className="search-box homesearch">
				<div className="search-option">
					<label className='search-lab' for="#"><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
						<path d="M12.0009 13.4304C13.724 13.4304 15.1209 12.0336 15.1209 10.3104C15.1209 8.5873 13.724 7.19043 12.0009 7.19043C10.2777 7.19043 8.88086 8.5873 8.88086 10.3104C8.88086 12.0336 10.2777 13.4304 12.0009 13.4304Z" stroke="white" stroke-width="1.5"></path>
						<path d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z" stroke="white" stroke-width="1.5"></path>
					</svg></span></label>
					<input type="text" name="search" onChange={(e) => {
						setSearch(e.target.value)
					}} placeholder='Search for Destinations, Courses, Experience..' />

				</div>
				<div className='searchbx-comdiv'>
					<div className="searchbx-date">
						{/* <label className='' for="#"><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                    viewBox="0 0 24 24" fill="none">
                                    <path d="M8 2V5" stroke="white" stroke-width="1.5" stroke-miterlimit="10"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16 2V5" stroke="white" stroke-width="1.5" stroke-miterlimit="10"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M3.5 9.08997H20.5" stroke="white" stroke-width="1.5" stroke-miterlimit="10"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path
                                        d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                                        stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M15.6937 13.7H15.7027" stroke="white" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M15.6937 16.7H15.7027" stroke="white" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11.9945 13.7H12.0035" stroke="white" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11.9945 16.7H12.0035" stroke="white" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8.29529 13.7H8.30427" stroke="white" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8.29529 16.7H8.30427" stroke="white" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg></span> Any Date</label> */}
						{/* <input type="text" placeholder='Any Date'/> */}
						{/* <DatePicker /> */}

						<div className="date-pick">
							<label htmlFor="date-picker" id='date-picker'>
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
										<path d="M8 2V5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
										<path d="M16 2V5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
										<path d="M3.5 9.08997H20.5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
										<path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
										<path d="M15.6937 13.7H15.7027" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
										<path d="M15.6937 16.7H15.7027" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
										<path d="M11.9945 13.7H12.0035" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
										<path d="M11.9945 16.7H12.0035" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
										<path d="M8.29529 13.7H8.30427" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
										<path d="M8.29529 16.7H8.30427" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
									</svg>
								</span>
							</label>
							<DatePicker
								// id="date-picker"
								selected={startDate}
								onChange={(date) => setStartDate(date)}
								dateFormat="dd - MM - yy"
								placeholderText="12 - 04 - 24"
							/>
						</div>

					</div>
					<div className="search-btn">
						<button>Search</button>
					</div>
				</div>
			</div>
		</form>
		
	);
}
