import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout';
import GolfHolidays from './GolfHolidays';
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants'
import {getGolfHolidayResults } from '../../Redux/actions/bookingActions'

const GolfHolidayList = () => {
     const [details, setDetails] = useState({'title':'Golftripz holidays','meta_desc':'Golftripz holidays','meta_keyword':'holidays , golftripz'})
     const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(new Date());
    const { availableHolidays }  = useSelector(state => state.booking)
     const [golfHolidays, setGolfHolidays] = useState(availableHolidays);

     var search = localStorage.getItem("holidaysearch");	
     const getGolfCourseData = async() => {
        let bookinData = JSON.parse(localStorage.getItem("holidaysearch"));
        
        if (bookinData === null || bookinData === '') {

             var data = {
                 'search':"",
                 'date':new Date(new Date()).toISOString().split('T')[0]
             }
             dispatch({
                 type: BOOKING_DATA,
                 payload: data
             })
             await dispatch(getGolfHolidayResults(data));
         }else{
            const ApiData = {
                'search':bookinData.holidaysearch,
                'date':new Date(bookinData.holidaystartdate).toISOString().split('T')[0]
            }
             dispatch({
                 type: BOOKING_DATA,
                 payload: ApiData
             })
             await  dispatch(getGolfHolidayResults(ApiData));
         }
        
     }
     useEffect(() => {
        getGolfCourseData()
      }, []);
    return (
        <Layout details={details} type='golfHoliday' className='golf-pad-remove'>
            <GolfHolidays/>
        </Layout>
    )
}

export default GolfHolidayList;
