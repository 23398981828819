import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import aboutus_img1 from '../../assets/images/aboutus_img1.jpg'
import aboutus_img2 from '../../assets/images/aboutus_img2.jpg'
import aboutus_img3 from '../../assets/images/aboutus_img3.jpg'
import safety_first from '../../assets/images/safety-first.png'
import filreset from '../../assets/images/lock_reset (1).png'
import Golfholidayimg from '../../assets/images/image 84.png'
import golfeventprofimg from '../../assets/images/golfeventprof.png'
import rectangle1 from '../../assets/images/image 56.png'
import rectangle2 from '../../assets/images/image 57.png'
import rectangle3 from '../../assets/images/image 58.png'
import rectangle4 from '../../assets/images/image 59.png'
import detailsbg from '../../assets/images/Rectangle 13064.png'
import mapin from '../../assets/images/map.png'
import emboximg1 from '../../assets/images/Rectangle 13406.png'
import emboximg2 from '../../assets/images/Rectangle 13407.png'
import detailpacimg1 from '../../assets/images/Rectangle 13451.png'
import detailpacimg2 from '../../assets/images/Rectangle 13452.png'
import detailpacimg3 from '../../assets/images/Rectangle 13453.png'
import tasksquare from '../../assets/images/task-square.png'
import reasonable_rates from '../../assets/images/resonable-rate.png'
import largest_fleet from '../../assets/images/largest-fleet.png'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
import { rest } from 'lodash';



import DatePicker from 'react-datepicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const GolfEvents = () => {


    const GolfEventsDetailsPage = () => {
        setAnchorElNav(null);
        navigation('/GolfEventsDetailsPage')
    };

    const navigation = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const { user } = useSelector(state => state.auth);


    // ----date--------------
    const [startDate, setStartDate] = useState(new Date());

     // tabview

     const [activeTab, setActiveTab] = useState('All');

     const tabs = ['All', 'Events', 'Nightlife', 'Heading 5', 'Heading 6', 'Heading 7'];

    // --------------

    return (
        <div className='listitem'>
            
            <div className='listitems-sec'>
                <Grid container spacing={2} className='list-dis-jus'>
                    <Grid xs={2.8} className='filter-content-box'>
                        <div className="filter-header">
                            <div className="fil-lft-cont">
                                <div className="fil-round">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32"
                                            fill="none">
                                            <path
                                                d="M27.4672 5.46647V8.39981C27.4672 9.46647 26.8005 10.7998 26.1338 11.4665L20.4005 16.5331C19.6005 17.1998 19.0672 18.5331 19.0672 19.5998V25.3331C19.0672 26.1331 18.5338 27.1998 17.8672 27.5998L16.0005 28.7998C14.2672 29.8665 11.8672 28.6665 11.8672 26.5331V19.4665C11.8672 18.5331 11.3338 17.3331 10.8005 16.6665L10.1738 16.0131C9.7605 15.5731 9.6805 14.9065 10.0138 14.3865L16.8405 3.42647C17.0805 3.0398 17.5072 2.7998 17.9738 2.7998H24.8005C26.2672 2.7998 27.4672 3.9998 27.4672 5.46647Z"
                                                fill="#232323" />
                                            <path
                                                d="M13.7999 4.83981L9.06654 12.4265C8.6132 13.1598 7.5732 13.2665 6.9732 12.6398L5.7332 11.3331C5.06654 10.6665 4.5332 9.46647 4.5332 8.66647V5.5998C4.5332 3.9998 5.7332 2.7998 7.19987 2.7998H12.6665C13.7065 2.7998 14.3465 3.94647 13.7999 4.83981Z"
                                                fill="#232323" />
                                        </svg>
                                    </span>
                                </div>
                                <p>Filters</p>
                            </div>
                            <div className="fil-rgt-cont">
                                <div className="fil-reset">
                                    <button>
                                        <span><img src={filreset} alt="reset-btn" /></span>Rest all
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='filter-boxes'>
                            <div className="filter-box location">
                                <h4>Location</h4>
                                <div className="radio-button-items">
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">All</label>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">England’s Golf Coast</label>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">London & Around</label>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-box price-range">
                                <h4>Price</h4>
                                <div className="range-bar">
                                    <div className="slider">
                                        <div className="progress"></div>
                                    </div>
                                    <div className="range-input">
                                        <input type="range" className='range-min' />
                                        <input type="range" className='range-max' />
                                    </div>
                                </div>
                            </div>
                            <div className="filter-box location">
                                <h4>Heading</h4>
                                <div className="radio-button-items">
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">All</label>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">England’s Golf Coast</label>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">London & Around</label>
                                    </div>
                                </div>
                            </div>

                            <div className="filter-box location">
                                <h4>Heading</h4>
                                <div className="radio-button-items">
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">All</label>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">England’s Golf Coast</label>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">London & Around</label>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-box price-range">
                                <h4>Heading</h4>
                                <div className="range-bar">
                                    <div className="slider">
                                        <div className="progress"></div>
                                    </div>
                                    <div className="range-input">
                                        <input type="range" className='range-min' />
                                        <input type="range" className='range-max' />
                                    </div>
                                </div>
                            </div>
                            <div className="filter-box location">
                                <h4>Heading</h4>
                                <div className="radio-button-items">
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">All</label>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">England’s Golf Coast</label>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">London & Around</label>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-box location">
                                <h4>Heading</h4>
                                <div className="radio-button-items">
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">All</label>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">England’s Golf Coast</label>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">London & Around</label>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-box location">
                                <h4>Heading</h4>
                                <div className="radio-button-items">
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">All</label>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">England’s Golf Coast</label>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" name='all' />
                                        <label for="All">London & Around</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={8.8} className='list-item-content'>
                        <h4>Golf Events in India</h4>
                        <p>Office ipsum you must be muted. T-shaped can air race hanging. Respectively social teams new lift
                            before pee red-flag busy of. Team market live businesses masking. These cc go container
                            inclusion better happenings place looking.
                        </p>
                        <div className='filter-tab-sec'>
                            <div className='filter-tab-list'>
                                <div className="tab-list-lft">
                                    <ul>
                                        {tabs.map(tab => (
                                            <li key={tab} className={tab === activeTab ? 'tab-active' : ''} onClick={() => setActiveTab(tab)}
                                            >
                                                {tab}
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="tab-list-rgt">
                                        <div className="sort">
                                            <div className="down-up-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                    viewBox="0 0 36 36" fill="none">
                                                    <path
                                                        d="M24.285 3H11.715C6.255 3 3 6.255 3 11.715V24.27C3 29.745 6.255 33 11.715 33H24.27C29.73 33 32.985 29.745 32.985 24.285V11.715C33 6.255 29.745 3 24.285 3ZM16.485 25.5C16.485 25.65 16.455 25.785 16.395 25.935C16.275 26.205 16.065 26.43 15.78 26.55C15.645 26.61 15.495 26.64 15.345 26.64C15.195 26.64 15.06 26.61 14.91 26.55C14.775 26.49 14.655 26.415 14.55 26.31L10.125 21.885C9.69 21.45 9.69 20.73 10.125 20.295C10.56 19.86 11.28 19.86 11.715 20.295L14.22 22.8V10.5C14.22 9.885 14.73 9.375 15.345 9.375C15.96 9.375 16.485 9.885 16.485 10.5V25.5ZM25.86 15.72C25.635 15.945 25.35 16.05 25.065 16.05C24.78 16.05 24.495 15.945 24.27 15.72L21.765 13.215V25.5C21.765 26.115 21.255 26.625 20.64 26.625C20.025 26.625 19.515 26.115 19.515 25.5V10.5C19.515 10.35 19.545 10.215 19.605 10.065C19.725 9.795 19.935 9.57 20.22 9.45C20.49 9.33 20.805 9.33 21.075 9.45C21.21 9.51 21.33 9.585 21.435 9.69L25.86 14.115C26.295 14.565 26.295 15.285 25.86 15.72Z"
                                                        fill="#292D32" />
                                                </svg>
                                            </div>
                                            <div className="sort-name">
                                                <p>Sort <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z"
                                                        fill="#292D32" />
                                                </svg></span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    {activeTab === 'All' &&
                                        <div className="search-result-list">
                                            <div className="golf-find-total">
                                                <h4>6 Golf Events found</h4>
                                            </div>
                                            <Grid container spacing={2} className='list-grid-no-mar'>
                                                <Grid xs={5.7} className="li-item-box">
                                                    <div className="listitem-card goliholidaycard">
                                                        <div className="listitem-image-sec">
                                                            <div className="listitem-img">
                                                                <img src={golfeventprofimg} alt="image" />
                                                            </div>
                                                            <div className="img-subtag">
                                                                <button onClick={GolfEventsDetailsPage}>Featured</button>
                                                                <button>Top pick</button>
                                                            </div>
                                                        </div>
                                                        <div className="listitem-bottom-con">
                                                            <div className="listitem-details">
                                                                <h4>Golf & Wine at Royal Meridian</h4>
                                                                <div className="listitem-duration">
                                                                    <p className="listitem-country-sec"><span><svg
                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 24 24" fill="none">
                                                                        <path
                                                                            d="M20.6191 8.45C19.5691 3.83 15.5391 1.75 11.9991 1.75C11.9991 1.75 11.9991 1.75 11.9891 1.75C8.45912 1.75 4.41912 3.82 3.36912 8.44C2.19912 13.6 5.35912 17.97 8.21912 20.72C9.27912 21.74 10.6391 22.25 11.9991 22.25C13.3591 22.25 14.7191 21.74 15.7691 20.72C18.6291 17.97 21.7891 13.61 20.6191 8.45ZM11.9991 13.46C10.2591 13.46 8.84912 12.05 8.84912 10.31C8.84912 8.57 10.2591 7.16 11.9991 7.16C13.7391 7.16 15.1491 8.57 15.1491 10.31C15.1491 12.05 13.7391 13.46 11.9991 13.46Z"
                                                                            fill="#292D32" />
                                                                    </svg></span>Bangalore, Ooty, Kochi & Chennai </p>

                                                                    <p><span><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 24 24" fill="none">
                                                                        <path
                                                                            d="M12.0001 4.65002C7.22008 4.65002 3.33008 8.54002 3.33008 13.32C3.33008 18.1 7.22008 22 12.0001 22C16.7801 22 20.6701 18.11 20.6701 13.33C20.6701 8.55002 16.7801 4.65002 12.0001 4.65002ZM12.7501 13C12.7501 13.41 12.4101 13.75 12.0001 13.75C11.5901 13.75 11.2501 13.41 11.2501 13V8.00002C11.2501 7.59002 11.5901 7.25002 12.0001 7.25002C12.4101 7.25002 12.7501 7.59002 12.7501 8.00002V13Z"
                                                                            fill="#292D32" />
                                                                        <path
                                                                            d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z"
                                                                            fill="#292D32" />
                                                                    </svg></span>12 Nights | 4 Rounds</p>
                                                                </div>
                                                            </div>
                                                            <div className='golfholi-bot-sec'>
                                                                <p>Play on quality golf courses in Bengaluru & end your trip with an Ayurvedic retreat in Kovalam, Kerala.</p>
                                                                <div className='golfholi-book-sec'>
                                                                    <p><span>From</span>INR 2,00,000</p>
                                                                    <button>Explore</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid xs={5.7} className="li-item-box">
                                                    <div className="listitem-card goliholidaycard">
                                                        <div className="listitem-image-sec">
                                                            <div className="listitem-img">
                                                                <img src={golfeventprofimg} alt="image" />
                                                            </div>
                                                            <div className="img-subtag">
                                                                <button>Featured</button>
                                                                <button>Top pick</button>
                                                            </div>
                                                        </div>
                                                        <div className="listitem-bottom-con">
                                                            <div className="listitem-details">
                                                                <h4>Golf & Wine at Royal Meridian</h4>
                                                                <div className="listitem-duration">
                                                                    <p className="listitem-country-sec"><span><svg
                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 24 24" fill="none">
                                                                        <path
                                                                            d="M20.6191 8.45C19.5691 3.83 15.5391 1.75 11.9991 1.75C11.9991 1.75 11.9991 1.75 11.9891 1.75C8.45912 1.75 4.41912 3.82 3.36912 8.44C2.19912 13.6 5.35912 17.97 8.21912 20.72C9.27912 21.74 10.6391 22.25 11.9991 22.25C13.3591 22.25 14.7191 21.74 15.7691 20.72C18.6291 17.97 21.7891 13.61 20.6191 8.45ZM11.9991 13.46C10.2591 13.46 8.84912 12.05 8.84912 10.31C8.84912 8.57 10.2591 7.16 11.9991 7.16C13.7391 7.16 15.1491 8.57 15.1491 10.31C15.1491 12.05 13.7391 13.46 11.9991 13.46Z"
                                                                            fill="#292D32" />
                                                                    </svg></span>Bangalore, Ooty, Kochi & Chennai </p>

                                                                    <p><span><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 24 24" fill="none">
                                                                        <path
                                                                            d="M12.0001 4.65002C7.22008 4.65002 3.33008 8.54002 3.33008 13.32C3.33008 18.1 7.22008 22 12.0001 22C16.7801 22 20.6701 18.11 20.6701 13.33C20.6701 8.55002 16.7801 4.65002 12.0001 4.65002ZM12.7501 13C12.7501 13.41 12.4101 13.75 12.0001 13.75C11.5901 13.75 11.2501 13.41 11.2501 13V8.00002C11.2501 7.59002 11.5901 7.25002 12.0001 7.25002C12.4101 7.25002 12.7501 7.59002 12.7501 8.00002V13Z"
                                                                            fill="#292D32" />
                                                                        <path
                                                                            d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z"
                                                                            fill="#292D32" />
                                                                    </svg></span>12 Nights | 4 Rounds</p>
                                                                </div>
                                                            </div>
                                                            <div className='golfholi-bot-sec'>
                                                                <p>Play on quality golf courses in Bengaluru & end your trip with an Ayurvedic retreat in Kovalam, Kerala.</p>
                                                                <div className='golfholi-book-sec'>
                                                                    <p><span>From</span>INR 2,00,000</p>
                                                                    <button>Explore</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid xs={5.7} className="li-item-box">
                                                    <div className="listitem-card goliholidaycard">
                                                        <div className="listitem-image-sec">
                                                            <div className="listitem-img">
                                                                <img src={golfeventprofimg} alt="image" />
                                                            </div>
                                                            <div className="img-subtag">
                                                                <button>Featured</button>
                                                                <button>Top pick</button>
                                                            </div>
                                                        </div>
                                                        <div className="listitem-bottom-con">
                                                            <div className="listitem-details">
                                                                <h4>Golf & Wine at Royal Meridian</h4>
                                                                <div className="listitem-duration">
                                                                    <p className="listitem-country-sec"><span><svg
                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 24 24" fill="none">
                                                                        <path
                                                                            d="M20.6191 8.45C19.5691 3.83 15.5391 1.75 11.9991 1.75C11.9991 1.75 11.9991 1.75 11.9891 1.75C8.45912 1.75 4.41912 3.82 3.36912 8.44C2.19912 13.6 5.35912 17.97 8.21912 20.72C9.27912 21.74 10.6391 22.25 11.9991 22.25C13.3591 22.25 14.7191 21.74 15.7691 20.72C18.6291 17.97 21.7891 13.61 20.6191 8.45ZM11.9991 13.46C10.2591 13.46 8.84912 12.05 8.84912 10.31C8.84912 8.57 10.2591 7.16 11.9991 7.16C13.7391 7.16 15.1491 8.57 15.1491 10.31C15.1491 12.05 13.7391 13.46 11.9991 13.46Z"
                                                                            fill="#292D32" />
                                                                    </svg></span>Bangalore, Ooty, Kochi & Chennai </p>

                                                                    <p><span><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 24 24" fill="none">
                                                                        <path
                                                                            d="M12.0001 4.65002C7.22008 4.65002 3.33008 8.54002 3.33008 13.32C3.33008 18.1 7.22008 22 12.0001 22C16.7801 22 20.6701 18.11 20.6701 13.33C20.6701 8.55002 16.7801 4.65002 12.0001 4.65002ZM12.7501 13C12.7501 13.41 12.4101 13.75 12.0001 13.75C11.5901 13.75 11.2501 13.41 11.2501 13V8.00002C11.2501 7.59002 11.5901 7.25002 12.0001 7.25002C12.4101 7.25002 12.7501 7.59002 12.7501 8.00002V13Z"
                                                                            fill="#292D32" />
                                                                        <path
                                                                            d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z"
                                                                            fill="#292D32" />
                                                                    </svg></span>12 Nights | 4 Rounds</p>
                                                                </div>
                                                            </div>
                                                            <div className='golfholi-bot-sec'>
                                                                <p>Play on quality golf courses in Bengaluru & end your trip with an Ayurvedic retreat in Kovalam, Kerala.</p>
                                                                <div className='golfholi-book-sec'>
                                                                    <p><span>From</span>INR 2,00,000</p>
                                                                    <button>Explore</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid xs={5.7} className="li-item-box">
                                                    <div className="listitem-card goliholidaycard">
                                                        <div className="listitem-image-sec">
                                                            <div className="listitem-img">
                                                                <img src={golfeventprofimg} alt="image" />
                                                            </div>
                                                            <div className="img-subtag">
                                                                <button>Featured</button>
                                                                <button>Top pick</button>
                                                            </div>
                                                        </div>
                                                        <div className="listitem-bottom-con">
                                                            <div className="listitem-details">
                                                                <h4>Golf & Wine at Royal Meridian</h4>
                                                                <div className="listitem-duration">
                                                                    <p className="listitem-country-sec"><span><svg
                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 24 24" fill="none">
                                                                        <path
                                                                            d="M20.6191 8.45C19.5691 3.83 15.5391 1.75 11.9991 1.75C11.9991 1.75 11.9991 1.75 11.9891 1.75C8.45912 1.75 4.41912 3.82 3.36912 8.44C2.19912 13.6 5.35912 17.97 8.21912 20.72C9.27912 21.74 10.6391 22.25 11.9991 22.25C13.3591 22.25 14.7191 21.74 15.7691 20.72C18.6291 17.97 21.7891 13.61 20.6191 8.45ZM11.9991 13.46C10.2591 13.46 8.84912 12.05 8.84912 10.31C8.84912 8.57 10.2591 7.16 11.9991 7.16C13.7391 7.16 15.1491 8.57 15.1491 10.31C15.1491 12.05 13.7391 13.46 11.9991 13.46Z"
                                                                            fill="#292D32" />
                                                                    </svg></span>Bangalore, Ooty, Kochi & Chennai </p>

                                                                    <p><span><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 24 24" fill="none">
                                                                        <path
                                                                            d="M12.0001 4.65002C7.22008 4.65002 3.33008 8.54002 3.33008 13.32C3.33008 18.1 7.22008 22 12.0001 22C16.7801 22 20.6701 18.11 20.6701 13.33C20.6701 8.55002 16.7801 4.65002 12.0001 4.65002ZM12.7501 13C12.7501 13.41 12.4101 13.75 12.0001 13.75C11.5901 13.75 11.2501 13.41 11.2501 13V8.00002C11.2501 7.59002 11.5901 7.25002 12.0001 7.25002C12.4101 7.25002 12.7501 7.59002 12.7501 8.00002V13Z"
                                                                            fill="#292D32" />
                                                                        <path
                                                                            d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z"
                                                                            fill="#292D32" />
                                                                    </svg></span>12 Nights | 4 Rounds</p>
                                                                </div>
                                                            </div>
                                                            <div className='golfholi-bot-sec'>
                                                                <p>Play on quality golf courses in Bengaluru & end your trip with an Ayurvedic retreat in Kovalam, Kerala.</p>
                                                                <div className='golfholi-book-sec'>
                                                                    <p><span>From</span>INR 2,00,000</p>
                                                                    <button>Explore</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    }
                                    {activeTab === 'Events' && <div>Events content here</div>}
                                    {activeTab === 'Nightlife' && <div>Nightlife content here</div>}
                                    {activeTab === 'Heading 5' && <div>Heading 5 content here</div>}
                                    {activeTab === 'Heading 6' && <div>Heading 6 content here</div>}
                                    {activeTab === 'Heading 7' && <div>Heading 7 content here</div>}
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
           </div>
        </div>
    )
}

export default GolfEvents;
