import React, { useState, useEffect } from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import GolfCourseDetails from './GolfCourseDetails';

const CourseDetailsPage = () => {
    const [details, setDetails] = useState({'title':'Golfcourses -Golftripz','meta_desc':'Golfcourses list','meta_keyword':'services , golftripz , golfcourses'})
    return (
        <Layout details={details}>
            <GolfCourseDetails count={5}/>
            
        </Layout>
    )
}

export default CourseDetailsPage;
