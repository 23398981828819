import React, { useState, useEffect } from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import GolfEventsDetails from './GolfEventsDetails';

const SearchDetailsPage = () => {
     const [details, setDetails] = useState({'title':'Golftripz Events Details','meta_desc':'Golftripz Events Details','meta_keyword':'events , golftripz , details'})
   
    return (
        <Layout head={"GolfEvents Details"} details={details}>
            <GolfEventsDetails count={5}/>
        </Layout>
    )
}

export default SearchDetailsPage;
