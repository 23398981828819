import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Button } from '@mui/material'
import bwhiteback from '../../assets/images/image 43.png'
import blogo from '../../assets/images/blue-logo.png'
import golfflag from '../../assets/images/golf flag.png'
import golfman from '../../assets/images/golfer.png'
import bEllipse1 from '../../assets/images/Ellipse 423 (1).png'
import bEllipse2 from '../../assets/images/Ellipse 424.png'
import bEllipse3 from '../../assets/images/Ellipse 425.png'
import bEllipse4 from '../../assets/images/Ellipse 426.png'
import clouds from '../../assets/images/white clouds.png'
import Layout from '../Layout';
import axios from 'axios';
import { API_URL, BASE_URL } from '../../config/Constants'
const Index = () => {

    const [banners, setBanners] = useState([])
  
    useEffect(() => {
        GetBanners(); 
    }, [])
    const GetBanners = () => {
       
        axios(API_URL+'/banner-image',{
            method: 'GET',
            headers: {
             'Content-Type': 'application/json'
           }
          })
            .then(function(response) {
                // return response.json()
                console.log(response);
                var body =response.data.body;
                setBanners(response.data.body);
               
              }).then(function(body) {
                console.log(body);
              });
          }

    return (
       
                <div className='banner-blue-sec'>
                <div className="white-corner">
                    <img src={bwhiteback} alt="image" />
                </div>
                <div className="blue-sec-logo">
                    <img src={blogo} alt="image" />
                </div>
                <div className="bottom-curve">
                    <svg width="1000px" height="168" viewBox="0 0 1218 168" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 63.3985C60.9772 110.019 223.34 162.542 431.706 169.522H525.294C777.916 161.112 873.652 116.961 929.289 63.3985C992.445 -2.67848 1138.6 -73.9615 1218 169.522H525.294C493.24 170.589 461.987 170.537 431.706 169.522H0V63.3985Z" fill="#1E3D58" />
                    </svg>
                </div>
                <div className="golf-flag">
                    <img src={golfflag} alt="image" />
                </div>
                <div className="golf-man">
                    <img src={golfman} alt="image" />
                </div>
                <div className="w-coluds">
                    <img src={clouds} alt="image" />
                </div>
                <div className='full-con-width'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5} sx={12}>
                            <div className="blue-sec-con">
                                <div className="square">
                                    <div className="squ-empty t-lft"></div>
                                    <div className="squ-empty t-rgt"></div>
                                    <div className="squ-empty b-lft"></div>
                                    <div className="squ-empty b-rgt"></div>
                                    <h2>Build your Own packages!</h2>
                                </div>
                                <p>Add whatever you want including Courses,Hotels, Holidays, Cars etc, by giving you the freedom of what you choose.</p>
                                <button className="ex-btn">Explore</button>
                                <div className="arrow-img">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="134px" height="100px" viewBox="0 0 148 155" fill="none">
                                        <path d="M147.593 149.283C148.329 151.07 147.477 153.116 145.69 153.852C143.903 154.589 141.857 153.737 141.121 151.95L147.593 149.283ZM1.41218 27.4873C0.118842 26.0507 0.234964 23.8377 1.67154 22.5443L25.082 1.46822C26.5185 0.174886 28.7316 0.291 30.0249 1.72758C31.3182 3.16417 31.2021 5.3772 29.7655 6.67052L8.95628 25.4049L27.6906 46.2141C28.9839 47.6507 28.8678 49.8637 27.4312 51.1571C25.9947 52.4504 23.7816 52.3343 22.4883 50.8977L1.41218 27.4873ZM141.121 151.95C129.732 124.314 119.357 94.8957 99.7705 71.5348C80.409 48.4419 51.8899 31.1625 3.82993 28.6407L4.19673 21.6503C54.0681 24.2672 84.4452 42.3608 105.135 67.0375C125.599 91.4462 136.43 122.194 147.593 149.283L141.121 151.95Z" fill="white" />
                                    </svg>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={7} sx={12} className='po-rel'>
                            <div class="cir-sec">
                                <img src={bEllipse1} alt="image" />
                            </div>
                            <div class="cir-sec-sm1">
                                <img src={bEllipse2} alt="image" />
                            </div>
                            <div class="cir-sec-sm2">
                                <img src={bEllipse3} alt="image" />
                            </div>
                            <div class="cir-sec-sm3">
                                <img src={bEllipse4} alt="image" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
       
    )
}

export default Index
